import React, {Component} from 'react';
import {ChatCloseContainer, ChatHeader, ChatInfo, CompanyLogo, HeaderButton, Title} from './headerStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAdjust, faFont, faTimes} from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import FitText from '@kennethormandy/react-fittext';

interface Props {
  title?: string;
  customerLogoUrl?: string;
  onClick: Function;
  onClose: Function;
  embedded: boolean;
  store: Object;
  chatCorners: string;
  headerExists: boolean;
  widgetFont: string;
  widgetFontSize: string;
  customerLogoWidthPercentage: number;
}

class Header extends Component<Props> {
  /**
   * Conditional rendering of close button
   * @returns {JS<Component>}
   */
  renderCloseButton = (embedded, chatIsActive, isHighContrastActive, isLargerFontMode, enableHighContrastOption, enableFontSizeOption) => (
    (
      <ChatCloseContainer customerLogoWidthPercentage={this.props.customerLogoWidthPercentage}>
        {enableFontSizeOption && (
          <HeaderButton aria-label={i18n.t('screenReaderSmallFont')}
                        className={!isLargerFontMode ? 'is-active small-font' : 'small-font'}
                        onClick={this.setSmallerFontSize} tabIndex={0}>
            <FontAwesomeIcon icon={faFont}/>
            <span className="sr-only">{i18n.t('screenReaderSmallFont')}</span>
          </HeaderButton>
        )}
        {enableFontSizeOption && (
          <HeaderButton aria-label={i18n.t('screenReaderLargeFont')} className={isLargerFontMode ? 'is-active' : ''}
                        onClick={this.setLargerFontSize} tabIndex={0}>
            <FontAwesomeIcon icon={faFont}/>
            <span className="sr-only">{i18n.t('screenReaderLargeFont')}</span>
          </HeaderButton>
        )}
        {enableHighContrastOption && (
          <HeaderButton aria-label={i18n.t('screenReaderHighContrastMode')}
                        className={isHighContrastActive ? 'is-active' : ''} onClick={this.toggleIsHighContrastActive}
                        tabIndex={0}>
            <FontAwesomeIcon icon={faAdjust}/>
            <span className="sr-only">{i18n.t('screenReaderHighContrastMode')}</span>
          </HeaderButton>
        )}
        {!embedded && (
          <HeaderButton aria-label={i18n.t('screenReaderClose')} onClick={this.props.onClose} tabIndex={0}>
            <FontAwesomeIcon icon={faTimes}/>
            <span className="sr-only">{i18n.t('screenReaderClose')}</span>
          </HeaderButton>
        )}
      </ChatCloseContainer>
    )
  );

  render() {
    const {chatIsActive, isHighContrastActive, isLargerFontMode} = this.props.store;
    const {
      title,
      customerLogoUrl,
      onClick,
      handleKeyDown,
      embedded,
      shadows,
      widgetShape,
      headerExists,
      widgetFontSize,
      widgetFont,
      customerLogoSideMargins,
      titleAlignment,
      customerLogoWidthPercentage,
      fixedSize,
      enableHighContrastOption,
      chatLocationVertical,
      enableFontSizeOption,
      themeColor,
    } = this.props;

    const widgetShapePieces = widgetShape.split(' ');
    const widgetShapeTopLeft = widgetShapePieces.length === 1 ? widgetShapePieces[0] : (widgetShapePieces.length === 2 ? widgetShapePieces[0] : widgetShapePieces[0]);
    const widgetShapeTopRight = widgetShapePieces.length === 1 ? widgetShapePieces[0] : (widgetShapePieces.length === 2 ? widgetShapePieces[1] : widgetShapePieces[1]);

    return (
      <ChatHeader onClick={onClick} onKeyDown={handleKeyDown} embedded={embedded} headerExists={headerExists}
                  shadows={shadows}
                  widgetShapeTopLeft={widgetShapeTopLeft} widgetShapeTopRight={widgetShapeTopRight}
                  fixedSize={JSON.parse(fixedSize)} chatLocationVertical={chatLocationVertical} themeColor={themeColor}
                  chatIsActive={chatIsActive} tabIndex={0}>
        <CompanyLogo url={customerLogoUrl} embedded={embedded} customerLogoWidthPercentage={customerLogoWidthPercentage}
                     aria-label={i18n.t('screenReaderLogo')} customerLogoSideMargins={customerLogoSideMargins}/>
        <ChatInfo url={customerLogoUrl} customerLogoWidthPercentage={customerLogoWidthPercentage} embedded={embedded}
                  chatIsActive={chatIsActive}>
          <FitText defaultFontSize={widgetFontSize} compressor={1} minFontSize={this.getMinFontSize(title)}
                   maxFontSize={parseInt(widgetFontSize)}>
            <Title widgetFontSize={widgetFontSize} titleAlignment={titleAlignment}
                   widgetFont={widgetFont}>{title}</Title>
          </FitText>
        </ChatInfo>
        {
          chatIsActive && this.renderCloseButton(embedded, chatIsActive, isHighContrastActive, isLargerFontMode, JSON.parse(enableHighContrastOption), JSON.parse(enableFontSizeOption))
        }
      </ChatHeader>
    );
  }

  getMinFontSize = (title) => {
    if (title.length > 10) {
      return 10;
    }

    return 25;
  };

  toggleIsHighContrastActive = (event: Event) => {
    event.stopPropagation();
    this.props.store.toggleHighContrastActive();
    this.setState({isHighContrastActive: this.props.store.isHighContrastActive});
  };

  setLargerFontSize = (event: Event) => {
    event.stopPropagation();
    this.props.store.setLargerFontSize();
    this.setState({isLargerFontMode: this.props.store.isLargerFontMode});
  };

  setSmallerFontSize = (event: Event) => {
    event.stopPropagation();
    this.props.store.setSmallerFontSize();
    this.setState({isLargerFontMode: this.props.store.isLargerFontMode});
  };
}

export default Header;
