import styled from 'styled-components';

export const ChatIcon = styled.button`
  background: url(${props => props.logo});
  background-position: center center;
  background-size: cover;
  width: ${props => (props.chatIconWidth ? props.chatIconWidth : '50px')};
  height: ${props => (props.chatIconHeight ? props.chatIconHeight : '50px')};
  overflow: hidden;
  z-index: ${props => (props.zIndex ? props.zIndex : '9999')};
  border-top-left-radius: ${props => (props.chatIconShapeTopLeft ? props.chatIconShapeTopLeft : '0')}px;
  border-top-right-radius: ${props => (props.chatIconShapeTopRight ? props.chatIconShapeTopRight : '0')}px;
  border-bottom-left-radius: ${props => (props.chatIconShapeBottomLeft ? props.chatIconShapeBottomLeft : '0')}px;
  border-bottom-right-radius: ${props => (props.chatIconShapeBottomRight ? props.chatIconShapeBottomRight : '0')}px;
  border-color: transparent;
  border-style: solid;
  position: fixed;
  cursor: pointer;
  ${props => props.chatIconPosition};
  display: ${props => (props.embedded || props.useChatIcon === 'false') ? 'none' : 'initial'};
  background-repeat: no-repeat;

  :focus {
    outline: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const WelcomeBubble = styled.div`
  position: fixed;
  width: ${props => (props.fixedSize === 'true' ? props.width : '380px')};
  border: 5px solid ${props => props.themeColor};
  background-color: white;
  padding: 10px;
  border-top-left-radius: ${props => (props.cornerShapeTopLeft ? props.cornerShapeTopLeft : '0')}px;
  border-top-right-radius: ${props => (props.cornerShapeTopRight ? props.cornerShapeTopRight : '0')}px;
  border-bottom-left-radius: ${props => (props.cornerShapeBottomLeft ? props.cornerShapeBottomLeft : '0')}px;
  border-bottom-right-radius: ${props => (props.cornerShapeBottomRight ? props.cornerShapeBottomRight : '0')}px;
  z-index: ${props => (props.zIndex ? props.zIndex : '9999')};
  overflow: hidden;
  visibility: ${props => props.welcomeBubbleVisibility};
  ${props => props.welcomeBubblePosition}
  display: flex;

  .text {
    cursor: pointer;
    width: 100%;
  }

  .close-btn {
    float: right;
  }
`;

export const Container = styled.div`
  // General CSS resets begin
  input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
    border-radius: initial;
    background-color: initial;
    border-style: initial;
    border-width: initial;
    border-color: initial;
    box-shadow: initial;
    color: initial;
    display: initial;
    margin: initial;
    padding: initial;
    height: initial;
    width: initial;
    box-sizing: initial;
    transition: initial;
  }

  button {
    text-transform: none;
    line-height: 1;
  }

  // General CSS resets end

  display: ${props => (props.embedded || (!props.embedded && props.useChatIcon === 'false')) ? 'inherit' : (props.chatIsActive ? 'initial' : 'none')};
  box-sizing: border-box;
  z-index: ${props => (props.zIndex ? props.zIndex : '9999')};
  will-change: transform;
  transition: .45s cubic-bezier(0.11, 0.79, 0.35, 0.99) .05s;
  border: ${props => props.showWidgetBorders === 'true' ? '1px solid #eee' : 'none'};
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  width: ${props => props.embedded ? (props.fixedSize === 'true' ? props.width : '100%') : (props.fixedSize === 'true' ? props.width : '380px')};
  height: ${props => props.embedded ? (props.fixedSize === 'true' ? props.height : '98%') : (props.chatIsActive ? (props.fixedSize === 'true' ? props.height : '500px') : '60px')};
  min-width: ${props => props.embedded ? (props.fixedSize === 'true' ? props.width : 'auto') : (props.fixedSize === 'true' ? props.width : '380px')};
  min-height: ${props => props.embedded ? (props.fixedSize === 'true' ? props.height : '0px') : (props.chatIsActive ? (props.fixedSize === 'true' ? props.height : '500px') : '60px')};
  position: ${props => props.embedded ? 'relative' : 'fixed'};
  box-shadow: ${props => props.embedded ? 'none' : (props.shadows ? props.shadows : 'none')};

  ${props => props.chatLocationVertical}: ${props => props.chatIsActive && !props.embedded ? props.chatMarginVertical : '0'};
  ${props => props.chatLocationHorizontal === 'left' ? 'left' : 'right'}: ${props => props.embedded ? '0' : (props.chatMarginHorizontal ? props.chatMarginHorizontal : '20px')};

  @media (max-width: 768px) {
    width: ${props => props.embedded ? (props.fixedSize === 'true' ? props.width : '100%') : '90vw'};
    min-width: initial;
  }

  @media (max-height: calc(${props => props.height} + 50px)) {
    height: ${props => props.chatIsActive ? 'calc(100% - 50px)' : '60px'};
    min-height: ${props => props.embedded ? (props.fixedSize === 'true' ? 'calc(100% - 50px)' : '0px') : (props.chatIsActive ? 'calc(100% - 50px)' : '60px')};
  }

  border-top-left-radius: ${props => (props.widgetShapeTopLeft ? props.widgetShapeTopLeft : '0')}px;
  border-top-right-radius: ${props => (props.widgetShapeTopRight ? props.widgetShapeTopRight : '0')}px;
  border-bottom-left-radius: ${props => (props.widgetShapeBottomLeft ? props.widgetShapeBottomLeft : '0')}px;
  border-bottom-right-radius: ${props => (props.widgetShapeBottomRight ? props.widgetShapeBottomRight : '0')}px;

  ::after {
    content: "";
    background-color: ${props => props.backgroundColor};
    background-image: url("${props => !props.embedded ? (props.chatIsActive ? props.backgroundImageUrl : '') : props.backgroundImageUrl}");
    background-position: ${props => props.backgroundPosition};
    background-size: ${props => props.backgroundSize};
    background-repeat: ${props => props.backgroundRepeat};
    opacity: ${props => props.backgroundTransparency};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    border-top-left-radius: ${props => (props.widgetShapeTopLeft ? props.widgetShapeTopLeft : '0')}px;
    border-top-right-radius: ${props => (props.widgetShapeTopRight ? props.widgetShapeTopRight : '0')}px;
    border-bottom-left-radius: ${props => (props.widgetShapeBottomLeft ? props.widgetShapeBottomLeft : '0')}px;
    border-bottom-right-radius: ${props => (props.widgetShapeBottomRight ? props.widgetShapeBottomRight : '0')}px;
  }

  ${props => props.isBlackAndWhite ? '* {' +
    'background-color: black !important;' +
    'color: white !important;' +
    'border-color: white !important;' +
    'scrollbar-color: #FFFFFF transparent;' +
    'input, button { border: 1px solid #FFFFFF; }' +
    'path { fill: white !important }' +
    '::-webkit-scrollbar-thumb { background-color: white; } }' : ''}

`;
