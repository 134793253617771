import axios from 'axios';
import uniqid from 'uniqid';

const {REACT_APP_API_URL} = ((process.env: any): {[string]: string});

interface UserResponse {
  bubble: string;
  action: string;
  state: string;
  type: string;
}

interface Email {
  email: string;
  message: string;
  subject: string;
}

export class ApiClient {
  baseUrl: '';
  conversationId: string = '';
  connectorId: string = '';
  currentAction = '';


  constructor() {
    this.baseUrl = REACT_APP_API_URL;
  }

  init(connectorId: string) {
    this.connectorId = connectorId;
    this.getConversationId();
  }

  //Getting conversationId from sessionStorage if exists, otherwise will generate a new one
  getConversationId() {
    let conversationId = '';

    const storedId = localStorage.getItem(this.connectorId + '_conversationId');

    if (storedId) {
      conversationId = storedId;
    } else {
      const id = uniqid();
      //Creating a new conversation_id if not in session storage
      localStorage.setItem(this.connectorId + '_conversationId', id);
      conversationId = id;
    }

    this.conversationId = conversationId;
  }


  //Getting conversationToken from sessionStorage if exists
  getConversationToken() {
    let conversationToken = localStorage.getItem(this.connectorId + '_conversationToken');
    if (conversationToken === null) {
      conversationToken = '';
    }

    return conversationToken;
  }

  setConversationToken(object) {
    let conversationToken = '';

    if (object.conversationToken !== null && object.conversationToken !== undefined) {
      conversationToken = object.conversationToken;
    }
    localStorage.setItem(this.connectorId + '_conversationToken', conversationToken);
  }


  async getIntroResponse() {
    try {
      const response = await axios.post(`${this.baseUrl}/get_next_response/${this.connectorId}`, {
        starchat: {
          conversation_id: this.conversationId,
          conversation_token: this.getConversationToken(),
          message: '',
          evaluation: 'start',
          data: {
            GJ_CURRENT_URL: window.location.href
          }
        },
        currentAction: this.currentAction,
      });

      this.currentAction = response.data[0].action;
      this.setConversationToken(response.data[0]);

      return response.data[0];
    } catch (error) {
      throw new Error(`Someting went wrong while fetching intro response: ${error}`);
    }
  }

  async getResponseWithState(state: string, userResponse: UserResponse) {
    try {
      let params = {
        conversation_id: this.conversationId,
        conversationToken: this.getConversationToken(),
        evaluation: state,
        data: {
            GJ_CURRENT_URL: window.location.href
        }
      };

      // If we had a pre-existing message from old state, extend the message with user_input
      if (userResponse && userResponse.bubble) {
        params = {
          ...params,
          user_input: {text: userResponse.bubble.trim()},
        };
      }

      const response = await axios.post(`${this.baseUrl}/get_next_response/${this.connectorId}`, {
        starchat: params,
        currentAction: this.currentAction,
      });

      this.currentAction = response.data[0].action;
      this.setConversationToken(response.data[0]);
      return response.data[0];

    } catch (error) {
      throw new Error(`Someting went wrong while fetching escalate response: ${error}`);
    }
  }

  async postUserResponse(userResponse: UserResponse) {
    try {
      let data = userResponse.data
      if(data){
        data.GJ_CURRENT_URL = window.location.href
      }else{
        data = {GJ_CURRENT_URL: window.location.href}
      }
      let params = {
        conversation_id: this.conversationId,
        conversation_token: this.getConversationToken(),
        message: userResponse.bubble.trim(),
        data: data,
      };

      if (userResponse.state) {
        params = {
          ...params,
          state: userResponse.state,
        };
        params.threshold = 0.00;
      }

      const response = await axios.post(`${this.baseUrl}/get_next_response/${this.connectorId}`, {
        starchat: params,
        currentAction: this.currentAction,
      });

      this.setConversationToken(response.data[0]);

      return response.data[0];

    } catch (error) {
      throw new Error(`Someting went wrong while posting user response: ${error}`);
    }
  }

  async getScrapeInformation(url: string) {
    try {
      const response = await axios.post(`${this.baseUrl}/scrape/${this.connectorId}`, {
        url: url.trim(),
      });

      return response.data[0];
    } catch (error) {
      throw new Error(`Someting went wrong while getting link information: ${error}`);
    }
  }

  async postUserAction(state: string) {
    try {
      const response = await axios.post(`${this.baseUrl}/get_next_response/${this.connectorId}`, {
        starchat: {
          conversation_id: this.conversationId,
          conversationToken: this.getConversationToken(),
          message: '',
          state: state,
          data: {
            GJ_CURRENT_URL: window.location.href,
          }
        },
        currentAction: this.currentAction,
      });

      this.currentAction = response.data[0].action;
      this.setConversationToken(response.data[0]);
      return response.data[0];

    } catch (error) {
      throw new Error(`Someting went wrong while posting user action: ${error}`);
    }
  }

  async postUserEmail(emailBody: Email) {
    try {
      const response = await axios.post(`${this.baseUrl}/send_user_email`, {
        email: {
          ...emailBody,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error(`Something went wrong sending user message: ${error}`);
    }
  }

  async sendFeedback(indexInConversation, score) {
    try {
      await axios.post(`${this.baseUrl}/send_feedback/${this.connectorId}`, {
        starchat: {
          conversation_id: this.conversationId,
          conversation_token: this.getConversationToken(),
          indexInConversation: indexInConversation,
          score: score,
        },

      });
      return true;

    } catch (error) {
      return false;
    }
  }
}
