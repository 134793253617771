import React, {Component} from 'react';
import {toJS} from 'mobx';
import {Paragraph} from '../common/styles';
import {Response, Store} from '../common/types';
import ResponseOptions from './ResponseOptions';
import {BotIcon, BotTextBubble, Feedback, IconDiv, InlineDiv, LikeDiv, List} from './botResponseStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThumbsDown, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import uniqid from 'uniqid';

interface Props {
  response: Response;
  store: any | Store;
  chatCorners: string;
  inputFont: string;
  inputFontSize: string;
  buttonFont: string;
  buttonFontSize: string;
  likeOnColor: string;
  likeOffColor: string;
  sendFeedback: Function;
  hideFeedbackFromChatHistory: Function;
  hideResponseOptionsFromChatHistory: Function;
}

interface State {
  choiceIsMade: boolean;
  response: Response;
  fillColor: string,
}

class BotResponse extends Component<Props, State> {
  static defaultProps = {
    store: null,
  };

  state = {
    choiceIsMade: false,
    fillColor: this.props.likeOffColor,
  };

  onChoice = (responseId) => {
    this.setState({choiceIsMade: true});

    if (!this.props.reclickableButtons) {
      this.props.hideResponseOptionsFromChatHistory(responseId);
    }
  };

  onLike = (e, indexInConversation, responseId) => {
    this.props.sendFeedback(indexInConversation, 1.0);
    this.props.hideFeedbackFromChatHistory(responseId);
  };

  onDisLike = (e, indexInConversation, responseId) => {
    this.props.sendFeedback(indexInConversation, 0.0);
    this.props.hideFeedbackFromChatHistory(responseId);
  };

  render() {
    const {response: {id, action, bubble, actionInput, showFeedback, hide}, indexInConversation} = this.props;
    const {choiceIsMade} = this.state;
    const {
      chatCorners,
      bubbleCorners,
      inputFont,
      inputFontSize,
      botIconUrl,
      botIconHeight,
      botIconWidth,
      buttonFont,
      buttonFontSize,
      likeOffColor,
      likeOnColor,
      enableFeedback,
      embedded,
      fixedSize,
      width,
      shadows,
      botIconBorderRadius,
      isLast,
    } = this.props;
    const actionInputObject = toJS(actionInput);

    const bubbleCornerPieces = bubbleCorners.split(' ');
    const bubbleCornerTopLeft = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[0] : bubbleCornerPieces[0]);
    const bubbleCornerTopRight = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[1] : bubbleCornerPieces[1]);
    const bubbleCornerBottomLeft = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[1] : bubbleCornerPieces[2]);
    const bubbleCornerBottomRight = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[0] : bubbleCornerPieces[3]);

    const bubbleObject = toJS(bubble);

    let containsFullWidthContent = false;
    bubbleObject.forEach(b => {
      if (b && (b.indexOf('[video') > -1 || b.indexOf('[image') > -1)) {
        containsFullWidthContent = true;
      }
    });

    return (
      <BotTextBubble embedded={embedded} chatCorners={chatCorners} bubbleCornerTopLeft={bubbleCornerTopLeft}
                     bubbleCornerTopRight={bubbleCornerTopRight} bubbleCornerBottomLeft={bubbleCornerBottomLeft}
                     bubbleCornerBottomRight={bubbleCornerBottomRight} buttonFont={buttonFont}
                     buttonFontSize={buttonFontSize} fixedSize={fixedSize} width={width}>

        <InlineDiv embedded={embedded} fixedSize={fixedSize} width={width} botIconUrl={botIconUrl}
                   botIconWidth={botIconWidth} key={uniqid()}
                   className={containsFullWidthContent ? 'full-width-container' : ''}>
          {bubbleObject.map((b, i) => (
            <Paragraph key={uniqid()}
                       index={i}
                       isLast={isLast}
                       inputFont={inputFont}
                       inputFontSize={inputFontSize}
                       bubbleCornerTopLeft={bubbleCornerTopLeft}
                       bubbleCornerTopRight={bubbleCornerTopRight}
                       bubbleCornerBottomLeft={bubbleCornerBottomLeft}
                       bubbleCornerBottomRight={bubbleCornerBottomRight}
                       embedded={embedded}
                       fixedSize={fixedSize}
                       botIconUrl={botIconUrl}
                       botIconWidth={botIconWidth}
                       width={width}
                       className={this.getParagraphClasses(b, containsFullWidthContent, enableFeedback === true && showFeedback !== false && indexInConversation !== undefined && indexInConversation > 1 && (i === bubbleObject.length - 1))}
                       shadows={shadows}
                       aria-label={b}>

              {ReactHtmlParser(this.props.store.getProcessedText(b))}

              {enableFeedback === true && showFeedback !== false && indexInConversation !== undefined && indexInConversation > 1 && (i === bubbleObject.length - 1) && (
                <LikeDiv likeOnColor={likeOnColor} likeOffColor={likeOffColor} embedded={embedded} fixedSize={fixedSize}
                         width={width} key={uniqid()}>
                  <button onClick={(e) => {
                    this.onLike(e, indexInConversation, id);
                  }}><FontAwesomeIcon icon={faThumbsUp}/></button>
                  <button onClick={(e) => {
                    this.onDisLike(e, indexInConversation, id);
                  }}><FontAwesomeIcon icon={faThumbsDown}/></button>
                </LikeDiv>
              )}
            </Paragraph>
          ))}
        </InlineDiv>

        <IconDiv botIconUrl={botIconUrl} botIconHeight={botIconHeight} botIconWidth={botIconWidth}
                 botIconBorderRadius={botIconBorderRadius}>
          <BotIcon src={botIconUrl}/>
        </IconDiv>

        {!hide && action === 'show_buttons' && (
          <List>
            {
              <ResponseOptions
                input={actionInputObject}
                onChoice={this.onChoice}
                chatCorners={chatCorners}
                buttonFont={buttonFont}
                buttonFontSize={buttonFontSize}
                responseId={id}
              />
            }
          </List>
        )}
        {!hide && action === 'ask_feedback' && Array.isArray(actionInputObject) &&
        !choiceIsMade && (
          <Feedback>
            {
              <ResponseOptions
                input={actionInputObject}
                onChoice={this.onChoice}
                buttonFont={buttonFont}
                chatCorners={chatCorners}
                buttonFontSize={buttonFontSize}
                responseId={id}
              />
            }
          </Feedback>
        )}
      </BotTextBubble>
    );
  }

  getParagraphClasses(bubble, bubbleGroupContainsFullWidthContent, containsFeedback) {
    let classes = (bubble.indexOf('[video') > -1 || bubble.indexOf('[image') > -1) ? 'full-width' : (bubbleGroupContainsFullWidthContent ? 'fit-content' : '');

    if (containsFeedback) {
      classes += ' contains-feedback';
    }

    if (classes.indexOf(' ') === 0) {
      classes = classes.substring(1);
    }

    return classes;
  }
}

export default BotResponse;
