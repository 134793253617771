import styled, {keyframes} from 'styled-components';

export const appear = keyframes`
  from {
    transform: translateY(15px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Paragraph = styled.div`
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  display: block;
  opacity: ${props => props.isLast ? '0' : '1'};
  animation: ${appear} 0.15s ease-out ${({isLast, index}) => isLast ? `${index / 10}s` : '0s'} forwards;

  ${props => {
  if (!props.isLast) {
    return 'animation: none;';
  }
}}

  font-size: 16px;
  word-break: break-word;
  padding: 15px;
  color: #333;
  margin: ${props => props.index > 0 ? '20px' : '10px'} 0 0 0;
  border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
  font-family:  ${props => props.inputFont};
  font-size:  ${props => props.inputFontSize}px;
  ${props => props.shadows ? ('box-shadow: ' + props.shadows + ';') : ''}
  background-color: white;
  border-top-left-radius: ${props => props.bubbleCornerTopLeft}px;
  border-top-right-radius: ${props => props.bubbleCornerTopRight}px;
  border-bottom-left-radius: ${props => props.bubbleCornerBottomLeft}px;
  border-bottom-right-radius: ${props => props.bubbleCornerBottomRight}px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: block;
    margin-left: ${props => props.botIconUrl ? props.botIconWidth + 'px' : '0'};
  }
`;

export const HtmlSection = styled.div`
   border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
   color: white;
   padding: 10px;
   background: ${props => props.theme.accentColor || '#5039ff'};
   width: 150px;
   margin-left: 10px;
   height: auto;
}

`;

export const HtmlImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  height: auto;
  img{
      max-width: 150px;
    }
`;

export const HtmlTitle = styled.div`
  font-family: ${props => props.inputFont};
  font-size:  ${props => props.inputFontSize}px;
  font-weight: 700;
  width: auto;
`;

export const HtmlUrl = styled.div`
  font-family: ${props => props.inputFont};
  font-size:  ${props => props.inputFontSize}px;
  word-break: break-all;
  background: ${props => props.theme.accentColor || '#5039ff'};
  filter: brightness(60%);
  a {
    color: white;
      filter: brightness(100%);
  }
   a:visited {
    color: white;
      filter: brightness(100%);
  }
`;

