import styled from 'styled-components';
import invert from 'invert-color';

export const ChatInput = styled.section`
  padding-left: 10px;
  padding-right: 10px;
  bottom: ${props => props.showReference && !props.embedded ? '25px' : '0'};
  position: ${props => props.embedded ? 'relative' : 'absolute'};
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  ${props => props.shadows ? ('box-shadow: ' + props.shadows + ';') : ''}
  background-color: #fff;
`;

export const Input = styled.textarea`
  margin: auto;
  font-family:  ${props => props.inputFont};
  font-size:  ${props => props.inputFontSize}px;
  font: ${props => props.inputFontSize}px ${props => props.inputFont};
  flex-basis: ${props => props.embedded ? (props.label ? '90%' : '97%') : '75%'};
  display: inline-block;
  border: none;
  appearance: none;
  height: inherit;
  padding: 7px !important;
  outline: none;
  resize: none;
  margin-right: 10px !important;
  &:disabled {
    user-select: none;
    cursor: not-allowed;
  }

  :focus {
    outline: none !important;
    box-shadow: 0 0 3px #999;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-flex-preferred-size: 85% !important;
  }
`;

export const ButtonContainer = styled.div`
 flex-basis: ${props => props.embedded ? (props.label ? '10%' : '3%') : (props.label ? '25%' : '15%')};
 text-align: ${props => props.label ? 'center' : 'right'};
`;

export const SendButton = styled.button`
  border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
  font-family:  ${props => props.buttonFont};
  font-size:  ${props => props.buttonFontSize}px;
  font-weight: 600;
  margin: 0 auto;
  width: ${props => props.label ? '100' : '50'}px;
  height: ${props => props.label ? '35' : '50'}px;
  outline: none;
  background-color: ${props => props.theme.accentColor || '#5039ff'};
  color: ${props => (props.accentColor && (props.accentColor.indexOf('#') === 0 && (props.accentColor.length === 4 || props.accentColor.length === 7) && /^#[0-9A-F]{6}$/i.test(props.accentColor))) ? invert(props.theme.accentColor, true) : 'white'};
  border: none;
  cursor: pointer;

  &:active {
      ${props => props.shadows ? ('box-shadow: ' + props.shadows + ';') : ''}
  }
`;

export const Description = styled.section`
  position: ${props => props.embedded ? 'relative' : 'absolute'};
  bottom: ${props => props.embedded ? '0' : '5px'};
  width: ${props => props.embedded ? 'auto' : '100%'};
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: ${props => (props.footerColor && (props.footerColor.indexOf('#') === 0 && (props.footerColor.length === 4 || props.footerColor.length === 7))) ? props.footerColor : '#b7b7b7'};
  padding: ${props => props.embedded ? '5px' : '0'};
  & a {
    color: ${props => (props.footerColor && (props.footerColor.indexOf('#') === 0 && (props.footerColor.length === 4 || props.footerColor.length === 7))) ? props.footerColor : '#b7b7b7'};
    font-weight: bold;
  }
`;
export const SendIcon = styled.div`
  width: 30px;
  height: 30px;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.sendIconUrl});
  margin: 0 auto;
`;

