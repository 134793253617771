import styled from 'styled-components';

export const Content = styled.section.attrs(props => ({
  'aria-live': 'polite',
}))`
  display: block;
  overflow: auto;
  padding: ${props => props.embedded ? '0px 20px 20px 20px' : '0px 20px 5px 20px'};
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  margin-right: ${props => props.embedded ? '0px' : '0px'};
  margin-top: ${props => props.headerExists === 'true' ? '1' : (props.widgetShapeTopRight ? props.widgetShapeTopRight : '0')}px;
  max-height: ${props => {
  if (props.embedded) { // Is embedded
    if (props.fixedSize === 'true') { // Is embedded and has fixed size
      return 'calc(' + props.height + ' - ' + (props.headerExists === 'true' ? 180 : 125) + 'px)';
    } else { // Is embedded and does not have fixed size
      return 'initial';
    }
  } else { // Is not embedded
    if (props.headerExists === 'true') { // Is not embedded and has header
      if (props.fixedSize === 'true') { // Is not embedded, has header, and has fixed size
        return 'calc(100% - 150px)';
      } else { // Is not embedded, has header, but not fixed size
        return '350px';
      }
    } else { // Is not embedded and does not have header
      return (props.fixedSize === 'true' ? 'calc(100% - 108px - ' + (props.widgetShapeTopRight ? (props.widgetShapeTopRight + 'px') : '0px') + ')' : 'calc(392px - ' + (props.widgetShapeTopRight ? (props.widgetShapeTopRight + 'px') : '0px') + ')');
    }
  }
}};

  height: ${props => {
  if (props.embedded) { // Is embedded
    if (props.fixedSize === 'true') { // Is embedded and has fixed size
      return 'calc(' + props.height + ' - ' + (props.headerExists === 'true' ? 180 : 125) + 'px)';
    } else { // Is embedded and does not have fixed size
      if (props.headerExists === 'true') {
        return 'calc(100% - 165px)';
      } else {
        return 'calc(100% - 80px)';
      }
    }
  } else { // Is not embedded
    if (props.headerExists === 'true') { // Is not embedded and has header
      if (props.fixedSize === 'true') { // Is not embedded, has header, and has fixed size
        return 'calc(100% - 150px)';
      } else { // Is not embedded, has header, but not fixed size
        return '350px';
      }
    } else { // Is not embedded and does not have header
      return (props.fixedSize === 'true' ? 'calc(100% - 108px - ' + (props.widgetShapeTopRight ? (props.widgetShapeTopRight + 'px') : '0px') + ')' : 'calc(392px - ' + (props.widgetShapeTopRight ? (props.widgetShapeTopRight + 'px') : '0px') + ')');
    }
  }
}};

  @media (max-height: calc(${props => props.height} + 50px)) {
    height: ${props => props.chatIsActive && props.fixedSize !== 'true' ? 'calc(100% - 150px)' : ''};
    min-height: ${props => props.chatIsActive && props.fixedSize !== 'true' ? 'calc(100% - 150px)' : ''}};
  }

  scrollbar-color: ${props => props.theme.accentColor} transparent;

  ::-webkit-scrollbar-track
  {
      border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
      background-color: transparent;
  }

  ::-webkit-scrollbar
  {
      width: 12px;
      background-color: transparent;
  }

  ::-webkit-scrollbar-thumb
  {
      border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
      background-color: ${props => props.theme.accentColor};
  }
`;

export const InfoBox = styled.div`
  padding: 20px;
  background-color: ${props => props.disclaimerBackgroundColor};
  color: white;
  display: ${props => props.showInfoBox ? 'inherit' : 'none'};
  z-index: ${props => (props.zIndex ? props.zIndex : '9999')};
  overflow: hidden;
  margin-left: ${props => props.embedded ? '-10' : '-5'}px;
  margin-right: ${props => props.embedded ? '-10' : '-5'}px;
  margin-top: ${props => props.embedded ? '0' : '-5'}px;
  position: sticky;
  top: ${props => props.embedded ? '5' : '15'}px;
  font-family:  ${props => props.inputFont};
  font-size:  ${props => props.inputFontSize}px;

  a {
    color: white;
  }

  .disclaimer-text {
    margin-right: 12px;
  }

  .close-btn {
      color: #FFFFFF;
      font-weight: bold;
      font-size: 20px;
      line-height: 18px;
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: calc(50% - 10px);

      &:hover, &:focus {
        filter: brightness(75%);
      }
  }

  @media all and (-ms-high-contrast:none) {
    .close-btn {
      position: relative;
      float: right;
      margin-right: 5px;
      margin-top: 5px;
      right: 0;
      top: 0;
    }
  }
`;
