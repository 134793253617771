export const S3URLS = [
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Chat%20icon_Nena%CC%88pa%CC%88iva%CC%88.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_nenapaiva.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Bot_smiling-transparent-01.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_bot_smiling.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Chaticon_Kouvola.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_kouvola.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/HopLop_logo.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_hoplop.png"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Customer%20materials%20for%20bots/Bot_smiling-transparent-01.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_bot_smiling.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hub/4040542/hubfs/Customer%20materials%20for%20bots/kiinteistöliitto_logo_notext.png?t=1627457169239&width=860",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_kiinteistoliitto.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/bottihahmo.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_hoplop.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Viljo_reunat.svg",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_viljo.svg"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/robo-opo_1.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_robo-opo.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hub/4040542/hubfs/Customer%20materials%20for%20bots/Palta_Chaticon_Sidesidebar.png?t=1644491350728&width=860",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_palta-sidebar.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/aspakoti_tunnus.jpg",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_aspakoti.jpg"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/voinks_mie_auttaa_uusi.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo-voinks-auttaa.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/hs_vesi_rgb.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_hsvesi.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Touho_aspakoti.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_Touho-aspakoti.png"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Customer%20materials%20for%20bots/200304-Chatbot-Leo.svg",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_Leo.svg"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Customer%20materials%20for%20bots/200304-Chatbot-Leo-bg.svg",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_Leo-bg.svg"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hub/4040542/hubfs/Customer%20materials%20for%20bots/umeochattaikon.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_umea.png"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Customer%20materials%20for%20bots/getjenny-chatbot-icon-round-05.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_chatbot-round-05.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/sak_ikoni.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_sak.png"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Voas_Logo.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_VOAS.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/gif-test.gif",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_gif-test.gif"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/bot-icon-24.jpg",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_bot-24.jpg"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/LUT-LOGO-PNG.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_LUT.png"
  },
  {
    "hubspot":"https://cdn2.hubspot.net/hubfs/4040542/Customer%20materials%20for%20bots/Bot_smiling-transparent-01.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_bot_smiling.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/Nordion_endorsed_Weum-02-1.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_logo_Nordion-Weum.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/WEUM_chat-icon.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_WEUM.png"
  },
  {
    "hubspot":"https://f.hubspotusercontent40.net/hubfs/4040542/Customer%20materials%20for%20bots/getjenny-chatbot-for-business-icon-02.png",
    "s3":"https://jenny-widgets.s3.eu-central-1.amazonaws.com/files/chat_icon_for-business.png"
  }
];
