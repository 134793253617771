import React, {Component} from 'react';
import {Response} from '../common/types';
import {HtmlImage, HtmlSection, HtmlTitle, HtmlUrl, Paragraph} from '../common/styles.js';
import {UserTextBubble} from './userResponseStyles';

interface Props {
  response: Response,
  chatCorners: string;
  bubbleCorners: string;
  inputFont: string;
  inputFontSize: string;
}

class UserResponse extends Component<Props> {
  render() {
    const {bubble, hasPreviewLink, previewLinkInformation} = this.props.response;

    const bubbleCornerPieces = this.props.bubbleCorners.split(' ');
    const bubbleCornerTopLeft = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[1] : bubbleCornerPieces[1]);
    const bubbleCornerTopRight = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[0] : bubbleCornerPieces[0]);
    const bubbleCornerBottomLeft = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[0] : bubbleCornerPieces[3]);
    const bubbleCornerBottomRight = bubbleCornerPieces.length === 1 ? bubbleCornerPieces[0] : (bubbleCornerPieces.length === 2 ? bubbleCornerPieces[1] : bubbleCornerPieces[2]);

    return (
      <UserTextBubble chatCorners={this.props.chatCorners} bubbleCornerTopLeft={bubbleCornerTopLeft}
                      bubbleCornerTopRight={bubbleCornerTopRight} bubbleCornerBottomLeft={bubbleCornerBottomLeft}
                      bubbleCornerBottomRight={bubbleCornerBottomRight}>
        {hasPreviewLink !== true &&
        <Paragraph inputFont={this.props.inputFont}
                   inputFontSize={this.props.inputFontSize}
                   bubbleCornerTopLeft={bubbleCornerTopLeft}
                   bubbleCornerTopRight={bubbleCornerTopRight}
                   bubbleCornerBottomLeft={bubbleCornerBottomLeft}
                   bubbleCornerBottomRight={bubbleCornerBottomRight}
                   embedded={this.props.embedded}
                   fixedSize={this.props.fixedSize}
                   shadows={this.props.shadows}
                   width={this.props.width}>
          {bubble}
        </Paragraph>
        }

        {hasPreviewLink === true && <HtmlSection chatCorners={this.props.chatCorners}>
          <HtmlImage inputFont={this.props.inputFont} inputFontSize={this.props.inputFontSize}> <img
            src={previewLinkInformation.img} alt=""/> </HtmlImage>
          <HtmlTitle inputFont={this.props.inputFont}
                     inputFontSize={this.props.inputFontSize}>{previewLinkInformation.title}</HtmlTitle>
          <HtmlUrl inputFont={this.props.inputFont} inputFontSize={this.props.inputFontSize}><a
            href={previewLinkInformation.url}>{bubble}</a> </HtmlUrl>
        </HtmlSection>}
      </UserTextBubble>
    );
  }
}

export default UserResponse;
