const TEST_CHAT_HISTORY = [
    {
      'id': 'k6t6jufy',
      'action': 'show_buttons',
      'bubble': [
        'Hello! My name is Jenny, I\'m a human-directed chatbot. Please let me tell you more about myself.!!!',
      ],
      'actionInput': [
        {
          'state': '2b0431f3-5637-4043-ba87-26c6ff884370',
          'label': 'Meet Jenny\'s clients',
          'type': 'button',
        },
        {
          'state': '44ae1558-91b4-4645-9911-b7638f2d3776',
          'label': 'Learn how  to build your own bot',
          'type': 'button',
        },
        {
          'state': 'cb133662-23bd-4807-834b-99f888ce2b5b',
          'label': 'feedback',
          'type': 'button',
        },
        {
          'state': '0eae2149-8879-4f0f-a254-8e18dadf7819',
          'label': 'recruitment',
          'type': 'button',
        },
        {
          'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
          'label': 'video',
          'type': 'button',
        },
        {
          'state': 'bad620ff-f58e-4358-a47b-303d1c110fea',
          'label': 'picture',
          'type': 'button',
        },
        {
          'state': '7bca27f2-5659-4023-86f3-ee0009d9417d',
          'label': 'Gif',
          'type': 'button',
        },
      ],
      'indexInConversation': '1',
      'showFeedback': true,
      'hide': false,
    },
    {
      'id': 'k6t6kn65',
      'action': 'userResponse',
      'bubble': 'Gif',
      'state': '7bca27f2-5659-4023-86f3-ee0009d9417d',
      'type': 'button',
    },
    {
      'id': 'k6t6kr2s',
      'action': 'show_buttons',
      'bubble': [
        '[image src=\'https://media.giphy.com/media/g9582DNuQppxC/giphy-downsized-large.gif\'] ',
        'Cheers!',
        'Try again?',
      ],
      'actionInput': [
        {
          'state': 'bad620ff-f58e-4358-a47b-303d1c110fea',
          'label': 'picture',
          'type': 'button',
        },
        {
          'state': '7bca27f2-5659-4023-86f3-ee0009d9417d',
          'label': 'gif',
          'type': 'button',
        },
        {
          'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
          'label': 'video',
          'type': 'button',
        },
        {
          'state': '3081786f-f4f0-4a6c-9a03-6e6e620bc341',
          'label': 'Nope',
          'type': 'button',
        },
      ],
      'indexInConversation': '2',
      'showFeedback': true,
      'hide': false,
    },
    {
      'id': 'k6t6lwjo',
      'action': 'userResponse',
      'bubble': 'feedback',
      'state': '',
      'type': 'text',
      'hasPreviewLink': false,
      'previewLinkInformation': {},
    },
    {
      'id': 'k6t6lzvp',
      'action': 'ask_feedback',
      'bubble': [
        'We would love to hear your feedback about us or the chatbot?',
      ],
      'actionInput': [
        {
          'type': 'button',
          'label': '0',
        },
        {
          'type': 'button',
          'label': '1',
        },
        {
          'type': 'button',
          'label': '2',
        },
        {
          'type': 'button',
          'label': '3',
        },
        {
          'type': 'button',
          'label': '4',
        },
        {
          'type': 'button',
          'label': '5',
        },
        {
          'type': 'button',
          'label': '6',
        },
        {
          'type': 'button',
          'label': '7',
        },
        {
          'type': 'button',
          'label': '8',
        },
        {
          'type': 'button',
          'label': '9',
        },
        {
          'type': 'button',
          'label': '10',
        },
      ],
      'indexInConversation': '4',
      'showFeedback': true,
    },
    {
      'id': 'k6t6mgx5',
      'action': 'userResponse',
      'bubble': 'picture',
      'state': 'bad620ff-f58e-4358-a47b-303d1c110fea',
      'type': 'button',
    },
    {
      'id': 'k6t6mk0y',
      'action': 'show_buttons',
      'bubble': [
        '[image src=\'https://i.pinimg.com/originals/c4/fc/9b/c4fc9b9ba1044adfa1fda7a93da82088.jpg\'] ',
        'This is an alpaka.',
        'Try again?',
      ],
      'actionInput': [
        {
          'state': 'bad620ff-f58e-4358-a47b-303d1c110fea',
          'label': 'picture',
          'type': 'button',
        },
        {
          'state': '7bca27f2-5659-4023-86f3-ee0009d9417d',
          'label': 'gif',
          'type': 'button',
        },
        {
          'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
          'label': 'video',
          'type': 'button',
        },
        {
          'state': '3081786f-f4f0-4a6c-9a03-6e6e620bc341',
          'label': 'Nope',
          'type': 'button',
        },
      ],
      'indexInConversation': '5',
      'showFeedback': true,
      'hide': false,
    },
    {
      'id': 'k6t6mnii',
      'action': 'userResponse',
      'bubble': 'video',
      'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
      'type': 'button',
    },
    {
      'id': 'k6t6mqqh',
      'action': 'show_buttons',
      'bubble': [
        '[video src=\'https://www.youtube.com/embed/zsnXbwMoHBg\']',
        'Try again?',
      ],
      'actionInput': [
        {
          'state': 'bad620ff-f58e-4358-a47b-303d1c110fea',
          'label': 'picture',
          'type': 'button',
        },
        {
          'state': '7bca27f2-5659-4023-86f3-ee0009d9417d',
          'label': 'gif',
          'type': 'button',
        },
        {
          'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
          'label': 'video',
          'type': 'button',
        },
        {
          'state': '3081786f-f4f0-4a6c-9a03-6e6e620bc341',
          'label': 'Nope',
          'type': 'button',
        },
      ],
      'indexInConversation': '7',
      'showFeedback': true,
    },
    {
      'id': 'k6t6mnie',
      'action': 'userResponse',
      'bubble': 'video',
      'state': '203ca352-76a2-4f64-962c-acaacbc10a4f',
      'type': 'button',
    },
    {
      'id': 'k6t6mk0x',
      'action': 'show_carousel',
      'indexInConversation': '8',
      'bubble': 'carousel',
      'showFeedback': false,
      'hide': false,
      'actionInput': {
        'external_buttons': [{
          'label': 'External 1',
          'state': 'State to trigger',
          'type': 'Button type',
          'data': {'button': 'external1'},
        }],
        cards: [
          {
            image: 'https://i.insider.com/5484d9d1eab8ea3017b17e29?width=600&format=jpeg&auto=webp',
            title: 'Card title 1',
            subtitle: 'Card subtitle',
            card_buttons: [{
              label: 'Card button',
              state: 'State to trigger',
              type: 'Button type',
              data: {},
            },
              {
                label: 'Card button',
                state: 'State to trigger',
                type: 'Button type',
                data: {},
              },
              {
                label: 'Card button',
                state: 'State to trigger',
                type: 'Button type',
                data: {},
              },
              {
                label: 'Card button',
                state: 'State to trigger',
                type: 'Button type',
                data: {},
              }],
          },
          {
            image: 'https://placeholder.com/wp-content/uploads/2019/06/lorem-ipsum.png',
            title: 'Card title 2',
            subtitle: 'Card subtitle',
            card_buttons: [{
              label: 'Card button label',
              state: 'State to trigger',
              type: 'Button type',
              data: {},
            }],
          },
          {
            image: 'https://helpx.adobe.com/content/dam/help/en/indesign/how-to/add-placeholder-text/jcr_content/main-pars/image_790976538/add-placeholder-text-intro_1000x560.jpg',
            title: 'Card title 3',
            subtitle: 'Card subtitle',
            card_buttons: [{
              label: 'Card button label',
              state: 'State to trigger',
              type: 'Button type',
              data: {},
            }],
          },
          {
            image: 'https://picsum.photos/640/360',
            title: 'Card title 4',
            subtitle: 'Card subtitle',
            card_buttons: [{
              label: 'Card button label',
              state: 'State to trigger',
              type: 'Button type',
              data: {},
            }],
          },
        ],
      },
    },
  ]
;

export default TEST_CHAT_HISTORY;
