export const getBubble = (bubble: any): Array<string> => {
  let bubbleArray = [];
  if (/\n\n/.test(bubble)) {
    bubbleArray = bubble.split(/\n\n/);
  } else {
    bubbleArray.push(bubble);
  }
  return bubbleArray;
};

export const camelToKebab = (string: string) => {
  return string.replace(/([a-z])([A-Z])/g, '$1-$2');
};

