import styled from 'styled-components';

export const Title = styled.div`
  font-family:  ${props => props.widgetFont};
  color: ${props => props.theme.titleTextColor ? props.theme.titleTextColor : '#9A9A9A'};
  width: 100%;
  margin-right: 10px;
  text-align: ${props => (props.titleAlignment !== 'left' && props.titleAlignment !== 'right' && props.titleAlignment !== 'center') ? 'center' : props.titleAlignment};
`;

export const CompanyLogo = styled.div`
  flex-basis: ${props => props.url ? props.customerLogoWidthPercentage + '%' : '0%'};
  width: ${props => props.embedded ? '60px' : 'auto'};
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
  margin-left: ${props => props.url ? (props.customerLogoSideMargins ? props.customerLogoSideMargins + 'px' : '10px') : '0px'};
  margin-right: ${props => props.url ? (props.customerLogoSideMargins ? props.customerLogoSideMargins + 'px' : '10px') : '0px'};
`;

export const ChatInfo = styled.div`
  flex-basis: ${props => props.url ? ((100 - props.customerLogoWidthPercentage - 20) + '%') : '100%'};
  display: flex;
  align-items: center;
  margin-right: ${props => (props.embedded || (!props.embedded && props.chatIsActive)) ? '10px' : '0'};

  div {
    width: 100%;
  }
`;

export const ChatHeader = styled.section.attrs(props => ({
  'aria-expanded': props.embedded || (!props.embedded && props.chatIsActive),
}))`
  display:  ${props => props.headerExists ? 'flex' : 'none'};
  padding: 0 10px 0 0;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ddd;
  cursor: ${props => props.embedded ? 'default' : 'pointer'};
  ${props => props.shadows ? ('box-shadow: ' + props.shadows + ';') : ''};
  background-color: ${props => props.themeColor && (props.themeColor.length === 4 || props.themeColor.length === 7) ? props.themeColor : '#fff'};
  border-${props => props.chatLocationVertical === 'top' ? 'bottom' : 'top'}-left-radius: ${props => (props.widgetShapeTopLeft ? props.widgetShapeTopLeft : '0')}px;
  border-${props => props.chatLocationVertical === 'top' ? 'bottom' : 'top'}-right-radius: ${props => (props.widgetShapeTopRight ? props.widgetShapeTopRight : '0')}px;

  :focus {
    outline: none !important;
    box-shadow: 0 0 5px #999;
  }

  @media (max-width: 768px) {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
  }
`;

export const ChatCloseContainer = styled.div`
  flex-basis: 20%;
  display:  flex;
  align-items: baseline;
  justify-content: flex-end;

  .is-active {
    border-bottom: 1px solid ${props => props.theme.titleTextColor};
  }

  .small-font {
    width: 12px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const HeaderButton = styled.button`
  color: ${props => props.theme.titleTextColor};
  width: 17px;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover, &:focus {
    filter: brightness(75%);
  }

  svg {
    height: 15px;
    width: 15px;
  }

  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
