import styled from 'styled-components';
import {carousel} from './carousel';
import invert from 'invert-color';

export const CarouselContainer = styled.div.attrs(props => ({
  'aria-live': 'polite',
}))`
  ${carousel}

  .carousel-container {
    height: 300px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .carousel-item {
    border: 1px solid #DDD;
    border-radius: 5px;
    text-align: center;
    margin-top: -15px;
    margin-left: 1px;
    margin-right: 1px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: #FFFFFF;

    > div {
      height: 275px;
    }
  }

  .card-img {
    width: 100%;
    height: 50%;
    object-fit: contain;
    margin-top: 5px;
  }

  .card-content {
    position: relative;
    transition: top 0.5s;
    -webkit-transition: top 0.5s;
    overflow: hidden;
  }

  .external-buttons {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
`;

export const CardTitle = styled.div`
  font-weight: bold;
  font-family: ${props => props.inputFont};
  font-size: ${props => props.inputFontSize}px;
`;

export const CardSubTitle = styled.div`
  margin-bottom: 5px;
  font-family: ${props => props.inputFont};
  font-size: ${props => props.inputFontSize}px;
`;

export const CardButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.buttonFont};
  font-size: ${props => props.buttonFontSize}px;
  border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
  padding: 10px 10px;
  width: max-content;
  min-width: 60px;
  margin-bottom: 6px;
  margin-left: 3px;
  margin-right: 3px;
  color: #232323;
  outline: none;
  background: #fff;
  border: 1px solid ${props => props.theme.accentColor || '#333'};
  cursor: default;
  &:hover:not([disabled]), &:focus:not([disabled]) {
    cursor: pointer;
    background-color: ${props => props.theme.accentColor || '#333'};
    color: ${props => (props.theme.accentColor && (props.theme.accentColor.indexOf('#') === 0 && (props.theme.accentColor.length === 4 || props.theme.accentColor.length === 7) && /^#[0-9A-F]{6}$/i.test(props.theme.accentColor))) ? invert(props.theme.accentColor, true) : 'white'};
  }
`;
