import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {Store} from '../common/types';
import {Button, UnderlinedButton} from './botResponseStyles';
import {compose} from 'recompose';

interface State {
  chosen: boolean;
}

interface Props {
  option: any;
  onChoice: Function;
  store: any | Store,
  index: number;
  chatCorners: string;
  buttonFont: string;
  buttonFontSize: string;
}

class Option extends Component<Props, State> {
  static defaultProps = {
    store: null,
  };

  chooseOption = async () => {
    if (this.props.store.chatHasEnded) {
      return;
    }

    this.props.onChoice(this.props.responseId);
    await this.props.store.addUserResponseToHistory({
      action: 'userResponse',
      bubble: this.props.option.label,
      state: this.props.option.state,
      type: 'button',
    }, true);
  };

  render() {
    const {
      index,
      option: {label},
    } = this.props;

    if (this.props.option.url) {
      return <a href={this.props.option.url} target={this.props.option.target}>
        <UnderlinedButton onClick={() => this.chooseOption()} index={index} chatCorners={this.props.chatCorners} buttonFont={this.props.buttonFont} buttonFontSize={this.props.buttonFontSize} disabled={this.props.inputIsDisabled ? 'disabled' : ''} tabIndex={0} aria-label={label}>{label}</UnderlinedButton>
      </a>;
    }

    return <Button onClick={() => this.chooseOption()} index={index} chatCorners={this.props.chatCorners} buttonFont={this.props.buttonFont} buttonFontSize={this.props.buttonFontSize} disabled={this.props.inputIsDisabled ? 'disabled' : ''} tabIndex={0} aria-label={label}>{label}</Button>;
  }
}

export default compose(
  inject('store'),
  observer
)(Option);
