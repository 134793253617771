import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';
import App from './App';
import {ApplicationStore} from './stores/ApplicationStore';

const getBundles = () => {
  let bundles = document.getElementById('bundle') ? document.getElementById('bundle') : document.getElementsByClassName('getjenny-widget-bundle');

  if (bundles.length === 0) {
    return;
  }

  if (document.getElementById('bundle')) {
    const bundleArray = [];
    bundleArray[0] = bundles;
    bundles = bundleArray;
  }

  return bundles;
};

const createAppContainer = (elementId) => {
  if (!document.getElementById(elementId)) {
    const appContainer = document.createElement('div');
    appContainer.id = elementId;
    document.body.appendChild(appContainer);
  }
};

//bootstraping app
const renderApp = (root, App, applicationStore) => {
  ReactDOM.render(
    <Provider store={applicationStore}>
      <App/>
    </Provider>,
    root,
  );
};

// Clear old chat history
try {
  const bundles = getBundles();
  if (!bundles) {
    throw new Error(`Element with "bundle" id or with class "getjenny-widget-bundle" is missing from the page`);
  }

  for (let i = 0; i < bundles.length; i++) {
    const attrs = bundles[i].dataset;
    const timeout = attrs.timeout ? (attrs.timeout * 1000) : 300000;
    const timestamp = localStorage.getItem(attrs.connectorId + '_getjenny_timestamp');

    if (new Date() - Date.parse(timestamp) > timeout) {
      localStorage.removeItem(attrs.connectorId + '_chatHistory');
      localStorage.removeItem(attrs.connectorId + '_conversationId');
    }
  }
} catch (e) {
  console.error('Error clearing history: ' + e);
}

try {
  const bundles = getBundles();
  if (!bundles) {
    throw new Error(`Element with "bundle" id or with class "getjenny-widget-bundle" is missing from the page`);
  }

  for (let i = 0; i < bundles.length; i++) {
    let elementId = 'getGenny-floating-chat';

    const attrs = bundles[i].dataset;

    if (attrs.targetElement && attrs.targetElement !== '') {
      elementId = attrs.targetElement;
    } else {
      elementId = elementId + '-' + attrs.connectorId;
    }

    createAppContainer(elementId);

    //Initiate application store
    const applicationStore = new ApplicationStore();
    applicationStore.init(attrs);

    const root = document.getElementById(elementId);
    renderApp(root, App, applicationStore);
  }

} catch (error) {
  console.error('there was an error during initiation of the app: ', error);
}
