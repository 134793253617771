import React from 'react';
import styled, {keyframes} from 'styled-components';

const appear = keyframes`
  from {
    transform: translateY(15px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const loading = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const Container = styled.div`
  opacity: 0;
  margin-top: 20px;
  animation: ${appear} .2s cubic-bezier(0.2, 1.04, 0.81, 1.56) .1s forwards;
  display: inline-block;
  width: 80px;
  height: 40px;
`;

const Loader = styled.div`
  color: #ddd;
  font-size: 10px;
  left: 35px;
  bottom: 9px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.1s;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation-fill-mode: both;
  animation: ${loading} 1.2s infinite ease-in-out;

  &:after {
    left: 20px;
    animation-delay: -1.2s;
  }

  &:before {
    left: -20px;
    animation-delay: 1s;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    animation-fill-mode: both;
    animation: ${loading} 1.2s infinite ease-in-out;
  }
`;

const TypingAnimation = (props) => {
  return (
    <Container chatCorners={props.chatCorners}>
      <Loader/>
    </Container>
  );
};

export default TypingAnimation;
