import React, {Component} from 'react';
import Carousel from 'react-multi-carousel';
import {CardButton, CardSubTitle, CardTitle, CarouselContainer} from './ProductCarouselStyles';
import uniqid from 'uniqid';
import {compose} from 'recompose';
import {observer, inject} from 'mobx-react';

class ProductCarousel extends Component<Props> {
  render() {
    const responsive = {
      desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: this.props.embedded ? 4 : 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 80,
      },
      tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 60,
      },
      mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 40,
      },
    };

    return (
      <CarouselContainer>
        <Carousel
          key={this.props.id}
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          centerMode={false}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          deviceType={this.props.deviceType}
          itemClass="carousel-item"
          partialVisible={true}
          ref={(el) => (this.Carousel = el)}
          afterChange={(previousSlide, {currentSlide, onMove}) => {
            if (currentSlide === this.props.data.cards.length - 1) {
              this.Carousel.setState({
                transform: this.Carousel.state.transform - 20,
                currentSlide: currentSlide,
              });
            }
          }}
        >
          {this.props.data.cards.map(card => {
            return (
              <div className="card" key={uniqid()}>
                <img className="card-img" src={card.image} alt={card.title}/>
                <div className="card-content">
                  <CardTitle inputFont={this.props.inputFont}
                             inputFontSize={this.props.inputFontSize}>{card.title}</CardTitle>
                  <CardSubTitle inputFont={this.props.inputFont}
                                inputFontSize={this.props.inputFontSize}>{card.subtitle}</CardSubTitle>

                  {card.card_buttons.map(button => {
                    return (
                      <CardButton key={uniqid()}
                                  onClick={() => this.chooseOption(button.label, button.state, button.data)}
                                  chatCorners={this.props.chatCorners} buttonFont={this.props.buttonFont}
                                  buttonFontSize={this.props.buttonFontSize}
                                  disabled={this.props.store.chatHasEnded ? 'disabled' : ''} tabIndex={0}
                                  aria-label={button.label}>{button.label}</CardButton>
                    );
                  })}
                </div>
              </div>
            );
          })}

        </Carousel>

        <div className="external-buttons">
          {this.props.data.external_buttons.map(button => {
            return (
              <CardButton key={uniqid()} onClick={() => this.chooseOption(button.label, button.state, button.data)}
                          chatCorners={this.props.chatCorners} buttonFont={this.props.buttonFont}
                          buttonFontSize={this.props.buttonFontSize}
                          disabled={this.props.store.chatHasEnded ? 'disabled' : ''} tabIndex={0}
                          aria-label={button.label}>{button.label}</CardButton>
            );
          })}
        </div>
      </CarouselContainer>
    );
  }

  chooseOption = async (label: string, state: string, data: any) => {
    if (this.props.store.chatHasEnded) {
      return;
    }

    this.setState({choiceIsMade: true});

    /*if (!this.props.reclickableButtons) {
      this.props.store.hideResponseOptionsFromChatHistory(this.props.id);
    }*/

    await this.props.store.addUserResponseToHistory({
      action: 'userResponse',
      bubble: label,
      state: state,
      type: 'button',
      data: data,
    }, true);
  };
}

export default compose(
  inject('store'),
  observer,
)(ProductCarousel);
