import styled from 'styled-components';
import invert from 'invert-color';
import {Paragraph} from '../common/styles';

export const UserTextBubble = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;

  ${Paragraph} {
    background-color: ${({theme}) => theme.contactBackgroundColor};
    color: ${({theme}) => (theme.contactBackgroundColor && (theme.contactBackgroundColor.length === 4 || theme.contactBackgroundColor.length === 7) && /^#[0-9A-F]{6}$/i.test(theme.contactBackgroundColor)) ? invert(theme.contactBackgroundColor, true) : 'white'};
    border-top-left-radius: ${props => props.bubbleCornerTopLeft}px;
    border-top-right-radius: ${props => props.bubbleCornerTopRight}px;
    border-bottom-left-radius: ${props => props.bubbleCornerBottomLeft}px;
    border-bottom-right-radius: ${props => props.bubbleCornerBottomRight}px;
  }

  div {
    max-width: 60%;
  }
`;
