import React, {Component} from 'react';
import anchorme from 'anchorme';
import {observer, inject} from 'mobx-react';
import {HtmlImage, HtmlSection, HtmlTitle, HtmlUrl} from '../common/styles';
import type {Store} from '../common/types';
import {ButtonContainer, ChatInput, Description, Input, SendButton, SendIcon} from './footerStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {compose} from 'recompose';

interface State {
  responseBody: string;
  html: boolean;
  scrapeInfo: {};
  submitted: boolean;
}

interface Props {
  addUserResponseToHistory: Function;
  getLinkPreview: Function;
  store: any | Store;
}

class Footer extends Component<Props, State> {
  static defaultProps = {
    store: null,
  };
  input: any;
  state = {
    responseBody: '',
  };

  constructor() {
    super();
    this.input = React.createRef();
  }

  submitResponse = () => {
    const {responseBody} = this.state;
    const {nextResponseState} = this.props.store;
    let previewLink = {};

    if (this.state.html) {
      previewLink.title = this.state.scrapeInfo.title;
      previewLink.img = this.state.scrapeInfo.image;
      previewLink.url = this.state.scrapeInfo.url;
    }

    if (responseBody !== '') {
      this.props.addUserResponseToHistory({
        action: 'userResponse',
        bubble: responseBody,
        state: nextResponseState,
        type: 'text',
        hasPreviewLink: this.state.html,
        previewLinkInformation: previewLink,
      });

      this.setState({responseBody: ''});
      this.setState({submitted: true});
    }
  };

  async handleChange(value: string) {

    if (this.props.store.chatHasEnded) {
      this.setState({responseBody: ''});
      return;
    }

    this.setState({html: false});
    this.setState({submitted: false});

    const urls = anchorme(value, {
      list: true,
    });

    if (urls[0] !== undefined && urls[0].raw && urls[0].raw.includes('http')) {
      await this.getHtmlInformation(urls[0].raw);
    }

    this.setState({responseBody: value});
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitResponse();
    }
  };

  async getHtmlInformation(url: string) {
    const jsonInformation = await this.props.getLinkPreview(url);
    this.setState({scrapeInfo: jsonInformation});
    this.setState({html: true});
  }


  componentDidUpdate() {
    if (process.env.BABEL_ENV === 'production') {
      if (this.props.store.chatIsActive && window.innerWidth > 1024) {
        this.input.current.focus();
      }
    }
  }

  render() {
    const {responseBody} = this.state;
    const {inputIsDisabled, chatAttributes, embedded} = this.props.store;
    const showReference = chatAttributes.showGetjennyReference ? JSON.parse(chatAttributes.showGetjennyReference) : true;
    const placeHolder = chatAttributes.placeholder ? chatAttributes.placeholder : 'Type your question here';
    const label = chatAttributes.buttonLabel ? chatAttributes.buttonLabel : '';
    const {
      chatCorners,
      footerText,
      footerUrl,
      inputFont,
      inputFontSize,
      buttonFont,
      buttonFontSize,
      footerColor,
      shadows,
      sendIconUrl,
    } = chatAttributes;

    return (
      <React.Fragment>
        <ChatInput showReference={showReference} embedded={embedded} chatCorners={chatCorners} shadows={shadows}>
          <Input
            ref={this.input}
            type="text"
            value={responseBody}
            placeholder={placeHolder}
            onKeyPress={(e) => this.props.store.botIsTyping ? () => {
              e.preventDefault();
            } : this.handleKeyPress(e)}
            onChange={(e) => this.handleChange(e.target.value)}
            disabled={inputIsDisabled || this.props.store.chatHasEnded}
            embedded={embedded}
            chatCorners={chatCorners}
            inputFont={inputFont}
            inputFontSize={inputFontSize}
            aria-label={placeHolder}
            tabIndex={0}
          />
          <ButtonContainer embedded={embedded} label={label}>
            <SendButton chatCorners={chatCorners}
                        buttonFont={buttonFont}
                        buttonFontSize={buttonFontSize}
                        shadows={shadows}
                        label={label}
                        tabIndex={0}
                        disabled={this.props.store.botIsTyping}
                        onClick={() => this.submitResponse()}>{label ? label : (sendIconUrl ?
              <SendIcon sendIconUrl={sendIconUrl}/> : <FontAwesomeIcon icon={faPaperPlane}/>)}</SendButton>
          </ButtonContainer>
        </ChatInput>
        {this.state.html && this.state.submitted !== true && <HtmlSection chatCorners={chatCorners}>
          <HtmlImage inputFont={inputFont} inputFontSize={inputFontSize}> <img src={this.state.scrapeInfo.image}
                                                                               alt=""/> </HtmlImage>
          <HtmlTitle inputFont={inputFont} inputFontSize={inputFontSize}>{this.state.scrapeInfo.title}</HtmlTitle>
          <HtmlUrl inputFont={inputFont} inputFontSize={inputFontSize}><a
            href={this.state.scrapeInfo.url}>{this.state.responseBody}</a> </HtmlUrl>
        </HtmlSection>
        }
        {showReference &&
        <Description embedded={embedded} footerColor={footerColor}>
          <a href={footerUrl} target="_blank" rel="noopener noreferrer">{footerText}</a>
        </Description>
        }
      </React.Fragment>
    );
  }
}

export default compose(
  inject('store'),
  observer,
)(Footer);
