import {toJS} from 'mobx';

export const addChatHistoryToSessionStorage = (connectorId, chatHistory: Array<any>) => {
  const chatHistoryArray = toJS(chatHistory);
  const historyWithoutFeedbackButtons = chatHistoryArray.map((item, i) => {
    // Remove feedback_buttons
    if (item.action === 'feedback_buttons' && i !== chatHistoryArray.length - 1) {
      item.actionInput = [];
    }
    return item;
  });

  localStorage.setItem(connectorId + '_chatHistory', JSON.stringify(historyWithoutFeedbackButtons));
  localStorage.setItem(connectorId + '_getjenny_timestamp', new Date().toISOString());
};
