import React, {Component} from 'react';
import * as ReactDOM from 'react-dom';
import {observer, inject} from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';

import BotResponse from '../botResponse/BotResponse';
import UserResponse from '../userResponse/UserResponse';
import TypingAnimation from '../common/TypingAnimation';
import {Content, InfoBox} from './chatContentStyles';
import ProductCarousel from './ProductCarousel';

import {Response, Store} from '../common/types';
import {compose} from 'recompose';
import i18n from 'i18next';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HeaderButton} from '../header/headerStyles';

interface Props {
  store: any | Store;
  sendFeedback: Function;
  hideFeedbackFromChatHistory: Function;
}

class ChatContent extends Component<Props> {
  static defaultProps = {
    store: null,
  };
  chatContent: Object;
  enableScrollToBottom = true;
  state = {
    showInfoBox: true,
  };

  constructor() {
    super();
    this.chatContent = React.createRef();
  }

  timer = () => {
    if (this.enableScrollToBottom) {
      this.scrollToBottom();
    }
  };

  handleScroll = (event) => {
    const newValue = Math.round(event.srcElement.scrollHeight - event.srcElement.scrollTop) === event.srcElement.clientHeight;

    if (newValue !== this.enableScrollToBottom) {
      this.enableScrollToBottom = newValue;
    }
  };

  scrollToBottom = () => {
    const chatContent = this.chatContent.current;
    ReactDOM.findDOMNode(chatContent).scrollTop = chatContent.scrollHeight;
  };

  componentDidMount = () => {
    this.enableScrollToBottom = true;
    this.scrollToBottom();

    const intervalId = setInterval(this.timer, 1000);
    this.setState({intervalId: intervalId});

    const chatContent = this.chatContent.current;
    ReactDOM.findDOMNode(chatContent).addEventListener('scroll', this.handleScroll);

    const disclaimerClosed = localStorage.getItem(this.props.store.chatAttributes.connectorId + '_disclaimer_closed');
    if (disclaimerClosed === 'true') {
      this.setState({showInfoBox: false});
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate = () => {
    this.scrollToBottom();
  };

  hideInfoBox = () => {
    localStorage.setItem(this.props.store.chatAttributes.connectorId + '_disclaimer_closed', 'true');
    this.setState({showInfoBox: false});
  };

  render() {
    const {chatHistory, botIsTyping, embedded, chatIsActive} = this.props.store;
    const {
      chatCorners, bubbleCorners, inputFont, inputFontSize, botIconUrl, botIconHeight, botIconWidth, buttonFont,
      buttonFontSize, likeOnColor, likeOffColor, enableFeedback, headerExists, fixedSize, width, height, shadows,
      disclaimerText, disclaimerBackgroundColor, botIconBorderRadius, reclickableButtons, zIndex,
    } = this.props.store.chatAttributes;

    return (
      <Content ref={this.chatContent} botIsTyping={botIsTyping} embedded={embedded} chatCorners={chatCorners}
               headerExists={headerExists} fixedSize={fixedSize} height={height}
               widgetShapeTopRight={this.props.widgetShapeTopRight} chatIsActive={chatIsActive}>
        <InfoBox embedded={embedded} showInfoBox={this.state.showInfoBox && disclaimerText}
                 disclaimerBackgroundColor={disclaimerBackgroundColor} inputFontSize={inputFontSize} zIndex={zIndex}
                 inputFont={inputFont}>
          <HeaderButton aria-label={i18n.t('screenReaderCloseInfo')} className="close-btn" onClick={this.hideInfoBox}
                        tabIndex={0}>
            <FontAwesomeIcon icon={faTimes}/>
          </HeaderButton>
          <div className="disclaimer-text">{ReactHtmlParser(this.props.store.getProcessedText(disclaimerText))}</div>
        </InfoBox>

        {chatHistory && chatHistory.map((response: Response, i) => {
          switch (response.action) {
            case 'userResponse':
              return <UserResponse inputFont={inputFont}
                                   inputFontSize={inputFontSize}
                                   chatCorners={chatCorners}
                                   bubbleCorners={bubbleCorners}
                                   key={response.id}
                                   embedded={embedded}
                                   fixedSize={fixedSize}
                                   width={width}
                                   shadows={shadows}
                                   response={response}/>;
            case 'show_carousel':
              if (response.hide || !response.actionInput) {
                return null;
              }

              return <ProductCarousel
                key={response.id}
                id={response.id}
                data={response.actionInput}
                embedded={embedded}
                buttonFont={buttonFont}
                chatCorners={chatCorners}
                buttonFontSize={buttonFontSize}
                inputFont={inputFont}
                inputFontSize={inputFontSize}
                reclickableButtons={reclickableButtons}/>;
            default:
              return <BotResponse
                store={this.props.store}
                botIconUrl={botIconUrl}
                botIconWidth={botIconWidth}
                botIconHeight={botIconHeight}
                inputFont={inputFont}
                inputFontSize={inputFontSize}
                chatCorners={chatCorners}
                bubbleCorners={bubbleCorners}
                buttonFont={buttonFont}
                buttonFontSize={buttonFontSize}
                likeOnColor={likeOnColor}
                likeOffColor={likeOffColor}
                enableFeedback={JSON.parse(enableFeedback)}
                key={response.id}
                response={response}
                embedded={embedded}
                width={width}
                fixedSize={fixedSize}
                shadows={shadows}
                botIconBorderRadius={botIconBorderRadius}
                isLast={chatHistory.length === i + 1}
                reclickableButtons={reclickableButtons}
                indexInConversation={response.indexInConversation}
                sendFeedback={(indexInConversation, score) =>
                  this.props.sendFeedback(indexInConversation, score)
                }
                hideFeedbackFromChatHistory={(id) =>
                  this.props.store.hideFeedbackFromChatHistory(id)
                }
                hideResponseOptionsFromChatHistory={(id) =>
                  this.props.store.hideResponseOptionsFromChatHistory(id)
                }
              />;
          }
        })
        }

        {botIsTyping && <TypingAnimation chatCorners={chatCorners}/>}
      </Content>
    );
  }
}

export default compose(
  inject('store'),
  observer,
)(ChatContent);
