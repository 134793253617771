import styled, {keyframes} from 'styled-components';
import invert from 'invert-color';

export const BotTextBubble = styled.div`
  display: inherit;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;

  & .fit-content {
    width: fit-content;
  }

  & .full-width {
  }

  & .contains-feedback {
    padding-bottom: 0;
  }

  .iframe-container {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    padding-top: 56.25%;
    position: relative;
  }

  .iframe-container iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
  }

  .responsive-image, img {
    width: 100%;
    height: auto;
  }

  .full-width-container {
    width: 100%;
    max-width: 80%;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;
  }
`;

export const List = styled.div`
  padding-left: 0;
  list-style: none;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  & button:not(:first-child) {
    margin-left: 15px;
  }
`;

export const Feedback = styled(List)`
  display: flow-root;
  & button:not(:first-child) {
    margin-left: 1px;
  }
  & button {
    flex-basis: calc(100% / 12);
    width: 22px;
    height: 22px;
    padding: 0;
    min-width: 22px;
    max-width: 22px;
  }
`;

export const appear = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Button = styled.button`
  opacity: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.buttonFont};
  font-size: ${props => props.buttonFontSize}px;
  border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
  padding: 10px 10px;
  width: max-content;
  min-width: 60px;
  margin-bottom: 10px;
  animation: ${appear} 0.15s ease-out ${({index}) => `${index / 10}s`} forwards;
  color: #232323;
  outline: none;
  background: #fff;
  border: 1px solid ${props => props.theme.accentColor || '#333'};
  cursor: default;
  &:hover:not([disabled]), &:focus:not([disabled]) {
    cursor: pointer;
    background-color: ${props => props.theme.accentColor || '#333'};
    color: ${props => (props.theme.accentColor && (props.theme.accentColor.indexOf('#') === 0 && (props.theme.accentColor.length === 4 || props.theme.accentColor.length === 7) && /^#[0-9A-F]{6}$/i.test(props.theme.accentColor))) ? invert(props.theme.accentColor, true) : 'white'};
  }
`;

export const UnderlinedButton = styled(Button)`
  text-decoration: underline;
`;

export const Icon = styled.svg`
  display: inline-block;
  margin-right: 5px;
  width: 13px;
  height: 13px;
`;

export const Options = styled.div`
  width: 100%;
  float: right;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  & a:not(:first-child) {
    margin-left: 15px;
  }
  & a {
    border-radius: ${props => (props.chatCorners ? props.chatCorners : '0')}px;
  }
`;

export const IconDiv = styled.div`
  display: inline-block;
  width: ${props => props.botIconUrl ? props.botIconWidth : 0}px;
  height: ${props => props.botIconUrl ? props.botIconHeight : 0}px;
  margin-top: ${props => props.botIconUrl ? '-' + props.botIconHeight / 2 : 0}px;
  margin-right: 10px;
  vertical-align: bottom;
  border-radius: ${props => props.botIconBorderRadius ? props.botIconBorderRadius : 0}px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    order: 999;
  }
`;

export const BotIcon = styled.img`
  object-fit: contain;
`;

export const InlineDiv = styled.div`
  padding-left: ${props => props.botIconUrl ? props.botIconWidth : 0}px;
  max-width: ${props => props.embedded ? ((props.fixedSize === 'true' && parseInt(props.width) < 800) ? '70%' : '50%') : '70%'};
  margin-bottom: 10px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;
    flex-direction: column;
  }
`;

export const LikeDiv = styled.div`
  text-align: right;
  position: relative;
  top: 32px;
  right: -15px;
  margin-bottom: 10px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;

  .svg-inline--fa.fa-w-16{
    width: 20px;
  }

  .svg-inline--fa {
    font-size: 12px;
    height: 1.1em;
}

  button:focus {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    path{
      fill: ${props => props.likeOnColor};
    }
   }

  button:hover {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    path{
      fill: ${props => props.likeOnColor};
    }
   }

  button:active {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    path{
      fill: ${props => props.likeOnColor};
    }
   }

   button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    padding: 0 0 0 5px;
    height: 15px;
    path{
      fill: ${props => props.likeOffColor};
    }
  }

`;
