import React from 'react';
import Option from './Option';
import {Options} from './botResponseStyles';

interface Props {
  onChoice: Function;
  input: Array<string>;
  chatCorners: string;
  buttonFont: string;
  buttonFontSize: string;
  responseId: any;
}


class ResponseOptions extends React.Component<Props> {

  render() {
    const {input, onChoice, responseId} = this.props;

    return (
      <Options chatCorners={this.props.chatCorners}>
        {input && input.map((option, i) =>
          <Option chatCorners={this.props.chatCorners}
                  buttonFont={this.props.buttonFont}
                  buttonFontSize={this.props.buttonFontSize}
                  option={option} index={i} key={i} onChoice={onChoice} responseId={responseId}/>)
        }
      </Options>
    );
  }
}

export default ResponseOptions;
