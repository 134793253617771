import {action, decorate, get, observable, set} from 'mobx';
import uniqid from 'uniqid';
import {BotResponse, ChatAttributes} from '../components/common/types';
import {addChatHistoryToSessionStorage} from './utils';
import {getBubble} from '../components/common/utils';
import {ApiClient} from '../apiClient/ApiClient';
import {S3URLS} from '../components/common/S3_URLs';

interface Response {
  action: string;
  bubble: string;
  state: string;
  data: any;
}
interface Email {
  email: string;
  message: string;
  subject: string;
}

const {REACT_APP_API_URL} = ((process.env: any): {[string]: string});

export class ApplicationStore {
  environment = process.env.NODE_ENV || 'test';
  showBuilder = process.env.REACT_APP_SHOW_BUILDER === 'true' || (this.environment !== 'production');

  chatAttributes = {
    src: REACT_APP_API_URL + '/static/js/main.js',
    connectorId: '59f11420-3532-4a72-99f9-160fccd30ab4',
    title: '',
    titleAlignment: 'center',
    customerLogoUrl: '',
    accentColor: '',
    buttonLabel: '',
    placeholder: '',
    showGetjennyReference: '',
    botReplyDelay: '0.3',
    welcomeText: '',
    disableChatOnButtons: 'true',
    chatLocationHorizontal: 'right',
    chatMarginHorizontal: '20px',
    chatLocationVertical: 'bottom',
    chatMarginVertical: '0px',
    openChatOnLoad: 'false',
    themeColor: '#fff',
    titleTextColor: '#9A9A9A',
    footerColor: '#B7B7B7',
    targetElement: '',
    chatCorners: 0,
    bubbleCorners: '10 10 0 10',
    useChatIcon: 'false',
    chatIconShape: '10 10 10 10',
    chatIconPosition: 'bottom: 23px; right: 20px;',
    showWidgetBorders: 'true',
    shadows: '0 5px 10px -3px rgba(40,40,40,.1)',
    backgroundColor: '#f5f5f5',
    disclaimerText: 'Conversations will be saved to our database!',
    disclaimerBackgroundColor: '#2196F3',
    chatIconUrl: '',
    widgetShape: '0',
    botIconBorderRadius: 0,
    customerLogoWidthPercentage: 15,
    customerLogoSideMargins: 10,
    sendIconUrl: '',
    enableHighContrastOption: 'true',
    enableFontSizeOption: 'true',
    identifier: '',
    reclickableButtons: false,
    enableFeedback: false,
    chatIconWidth: '50px',
    chatIconHeight: '50px',
    contactBackgroundColor: '#e9f0fe'
  };

  collectInformation: Email = {};
  collectNextInformation = false;
  embedded = false;
  chatIsActive = false;
  isHighContrastActive = false;
  isLargerFontMode = false;
  chatIsOpenedBefore = false;
  botIsTyping = false;
  chatHistory = [];
  nextResponseState = '';
  transferMessage: Response = {};
  inputIsDisabled = false;
  chatHasEnded = false;
  nextResponseKeyword = [];
  welcomeBubbleVisibility = 'hidden';

  apiClient: ApiClient;
  intervalId = -1;

  TEST_CHAT_HISTORY: any;

  constructor() {
    this.apiClient = new ApiClient();

    if (process.env.REACT_APP_SHOW_BUILDER === 'true') {
      this.TEST_CHAT_HISTORY = require('../components/common/TestData');
    }
  }

  getDelay = () => {
    const delay = get(this.chatAttributes, 'botReplyDelay');

    if (delay !== '') {
      return parseFloat(delay) * 1000;
    } else {
      return Math.random() * (3000 - 1500) + 2000;
    }
  };

  // When action is normal and keyword is set
  getUserResponseState = (response: Response) => (
    this.nextResponseKeyword.reduce((prevValue, currValue) => {
      const pattern: RegExp = new RegExp(
        `${currValue.keyword.split(',').map((word) => {
          if (/^\*/.test(word.trim())) {
            return `${word.split('*')[1].trim()}$`;
          } else if (/\*$/.test(word.trim())) {
            return `^${word.split('*')[0].trim()}`;
          }
          return `^${word.trim()}$`;
        }).join('|')}`,
      );
      // If we have found a matching pattern use the state of found object else get response without state
      if (pattern.test(response.bubble)) {
        return {...response, state: currValue.state};
      }
      return prevValue;
    }, response)
  );

  init = (attrs: ChatAttributes) => {
    this.setChatAttributes(attrs);

    if (attrs.connectorId === 'BUILDER') {
      const testChatHistory = this.TEST_CHAT_HISTORY;
      localStorage.setItem(attrs.connectorId + '_getjenny_bot_id', 'BUILDER');
      localStorage.setItem(attrs.connectorId + '_chatHistory', JSON.stringify(testChatHistory.default));
      localStorage.setItem(attrs.connectorId + '_getjenny_timestamp', new Date().toISOString());
      localStorage.setItem(attrs.connectorId + '_conversationId', 'BUILDER');
      localStorage.setItem(attrs.connectorId + '_getjenny_bot_identifier', '');
    }

    const sessionChatOpen = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_chat_open');

    //Determining if chat should be shown or hidden on startup
    if (sessionChatOpen === 'true') {
      this.openChat();
      this.pushToGTM('getjenny_chat_open');
    } else {
      this.chatIsActive = false;
    }
    this.initLocalStorage();
  };

  initLocalStorage = () => {
    //Trying to get chat history from session storage
    let storedChatHistory = localStorage.getItem(this.chatAttributes.connectorId + '_chatHistory');
    let storedBotId = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_bot_id');
    const identifier = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_bot_identifier');

    if (identifier !== this.chatAttributes.identifier) {
      localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_bot_identifier', this.chatAttributes.identifier);
      localStorage.removeItem(this.chatAttributes.connectorId + '_chatHistory');
      localStorage.removeItem(this.chatAttributes.connectorId + '_conversationId');
      localStorage.removeItem(this.chatAttributes.connectorId + '_conversationToken');
      storedChatHistory = undefined;
      storedBotId = undefined;
    }

    if (storedChatHistory) {
      this.chatHistory = JSON.parse(storedChatHistory);

      const lastAction = this.chatHistory[this.chatHistory.length - 1];
      // Disable buttons of chat history's latest item is of type show_buttons
      if (lastAction && lastAction.action === 'show_buttons') {
        this.disableInput();
      }
      // If last chat's action is input_validation then set the nextResponseKeywords observeable
      if (lastAction && lastAction.action === 'input_validation' && typeof lastAction.actionInput !== 'undefined') {
        this.nextResponseKeyword = lastAction.actionInput;
      }
    }

    if (storedBotId) {
      this.updateAttribute('connectorId', storedBotId);
    }

    setInterval(() => {
      const currentChatHistory = JSON.stringify(this.chatHistory);
      const storedChatHistory = localStorage.getItem(this.chatAttributes.connectorId + '_chatHistory');

      if (currentChatHistory && storedChatHistory && currentChatHistory !== storedChatHistory) {
        this.chatHistory = JSON.parse(storedChatHistory);
      }

      if (!this.chatHasEnded) {
        for (const pieceOfHistory of this.chatHistory) {
          if (pieceOfHistory.action === 'end_chat' || pieceOfHistory.action === 'close_chat') {
            this.chatHasEnded = true;
          }
        }
      }
    }, 2000);
  };

  updateAttribute = (attribute: string, newValue: string) => {
    set(this.chatAttributes, attribute, newValue);

    if (attribute === 'connectorId') {
      this.apiClient.init(newValue);
    } else if (attribute === 'openChatOnLoad') {
      this.chatIsActive = !!JSON.parse(newValue);
    } else if (attribute === 'targetElement') {
      this.embedded = newValue !== '';
    } else if (attribute === 'disableChatOnButtons') {
      this.disableChatOnButtons = newValue;
    }
  };

  setChatAttributes = (vars: ChatAttributes): void => {
    this.fixHubspotLinksToS3(vars);

    Object.keys(vars).forEach((key) => {
      set(this.chatAttributes, key, vars[key]);

      if (key === 'targetElement' && vars[key] !== '') {
        this.embedded = true;
      }
    });

    this.apiClient.init(get(this.chatAttributes, 'connectorId'));
  };

  fixHubspotLinksToS3(vars) {
    const botIconReplaceable = S3URLS.find(s => s.hubspot === vars.botIconUrl);
    if (botIconReplaceable) {
      vars.botIconUrl = botIconReplaceable.s3;
    }

    const chatIconReplaceable = S3URLS.find(s => s.hubspot === vars.chatIconUrl);
    if (chatIconReplaceable) {
      vars.chatIconUrl = chatIconReplaceable.s3;
    }

    const custometLogoReplaceable = S3URLS.find(s => s.hubspot === vars.customerLogoUrl);
    if (custometLogoReplaceable) {
      vars.customerLogoUrl = custometLogoReplaceable.s3;
    }
  }

  addIntroResponse = async () => {
    const introResponse: BotResponse = await this.apiClient.getIntroResponse();
    this.randomizeResponseBubble(introResponse);
    this.addBotResponseToHistory(introResponse);
  };

  getLinkPreview = async (url: string) => {
    return await this.apiClient.getScrapeInformation(url);
  };

  addUserResponseToHistory = async (response: Response, withoutDelay: boolean) => {
    const id: string = uniqid();
    this.chatHistory.push({id, ...response});

    const filteredResponse = response;

    this.pushToGTM('getjenny_chat_interaction', filteredResponse.state);
    addChatHistoryToSessionStorage(this.chatAttributes.connectorId, this.chatHistory);

    await this.getBotResponse(filteredResponse, withoutDelay);
  };

  getBotResponse = async (response: Response, withoutDelay: boolean) => {
    // If previous item had action item_validation extend the object with state.
    const userResponse: Response = this.nextResponseKeyword.length > 0 ? this.getUserResponseState(response) : response;

    try {
      // If collect information state was triggered, store the information
      if (this.collectNextInformation) {
        set(this.collectInformation, this.collectNextInformation, response.bubble);
      }

      // Getting back response from server
      const botResponse = await this.apiClient.postUserResponse(userResponse);

      //First randomizing response that has strings in bubble separated by |
      this.randomizeResponseBubble(botResponse);

      this.addBotResponseToHistory(botResponse);

    } catch (error) {
      console.error(error);
    } finally {
      this.nextResponseKeyword = [];
    }
  };

  setBotTypingOn = () => {
    setTimeout(() => {
      this.botIsTyping = true;
    }, 0);
  };

  setBotTypingOff = () => {
    this.botIsTyping = false;
  };

  getNextBubbleResponse = (response: BotResponse, index: number) => {
    const id: string = uniqid();
    this.chatHistory.push({
      id,
      action: response.action,
      bubble: [response.bubble[index]],
      actionInput: response.action_input && response.action_input,
    });
    addChatHistoryToSessionStorage(this.chatAttributes.connectorId, this.chatHistory);
  };

  addBotResponseToHistory = (botResponse: BotResponse) => {
    const id: string = uniqid();

    this.handleBotResponseBubble(botResponse);
    switch (botResponse.action) {
      case 'show_buttons':
        this.handleButtonsResponse(id, botResponse);
        break;
      case 'ask_feedback':
        if (botResponse.action_input.buttons !== undefined) {
          this.handleFeedbackButtons(id, botResponse);
        } else {
          this.handleNextResponse(id, botResponse);
        }
        break;
      case '':
        botResponse.action_input = {};
        this.handleNextResponse(id, botResponse);
        break;
      case 'show_carousel':
        this.handleNextResponse(id, botResponse);
        break;
      case 'close_chat':
        this.handleNextResponse(id, botResponse);
        this.handleCloseChat();
        break;
      case 'end_chat':
        this.handleNextResponse(id, botResponse);
        this.handleCloseChat();
        break;
      case 'send_email':
        this.handleSendEmail(id, botResponse);
        break;
      case 'transfer':
        this.handleTransferBot(id, botResponse);
        break;
      case 'changeBot':
        this.handleChangeBot(id, botResponse);
        break;
     case 'open_zendesk':
        this.handleNextResponse(id, botResponse);
        this.handleZendesk(id, botResponse);
        break;
    case 'open_zendesk_custom':
        this.handleNextResponse(id, botResponse);
        this.handleZD(id, botResponse);
        break;
      default:
        console.info('This case might not be handled. Action: ', botResponse.action);
        this.updateChatHistory(id, botResponse);
        //Enable input if disabled
        this.enableInput();
        break;
    }
  };

  handleZendesk = (id: string, botResponse: BotResponse) => {
    var zE = window.parent.zE
    if(zE){
        zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    departments: {
                        enabled: [''],
                        select: botResponse.action_input.department
                    },
                    suppress: false
                }
            }
        })
        setTimeout(() => {
            this.closeChat()
            localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_show_zendesk', 'true');
            zE('webWidget', 'open')
            zE('webWidget', 'chat:send', botResponse.action_input.message)
            zE('webWidget', 'show')
        }, botResponse.action_input.delay)
    }
  }

    handleZD = (id: string, botResponse: BotResponse) => {
        var zE = window.parent.zE
        if(zE){
            zE('webWidget', 'updateSettings', {
                webWidget: {
                    chat: {
                        departments: {
                            enabled: [''],
                            select: botResponse.action_input.department
                        },
                        suppress: false
                    }
                }
            })
            setTimeout(() => {
                localStorage.setItem("zEChatMessage", botResponse.action_input.message);
                localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_show_zendesk', 'true');
                zE('webWidget', 'open')
                zE('webWidget', 'show')
                document.getElementById("getGenny-floating-chat-"+this.chatAttributes.connectorId).style.display = "none"
            }, botResponse.action_input.delay)
        }
    }
  /*
  handleP = (id: string, botResponse: BotResponse) => {
    // Add Zendesk script to page
    let key = botResponse.action_input.key
    let email = botResponse.action_input.email
    let name = botResponse.action_input.name
    let message = botResponse.action_input.message
    fetch('https://server1.pepron.com/espoonasunnot/sendtokenemail.php?email='+email+'&name='+name+'&url='+window.location.href).then(function(res){
        res.text().then(function(jwt) {
            localStorage.setItem("zEName", name);
            localStorage.setItem("zEEmail", email);
            localStorage.setItem("zEChatMessage", message);
            this.closeChat()
        })
    })
  }
  */
  /*
  var paramChatToken = getParameterByName("chattoken");
  console.log(paramChatToken);
  if (paramChatToken != null) {
    localStorage.setItem("zEChatToken", paramChatToken);
  }
  var storageChatToken = localStorage.getItem("zEChatToken");
  console.log(storageChatToken);
  if (storageChatToken) {
    $("#prechat_form").hide();
    prepareZendesk();
  } else {
    $("#senddata").click(getToken);
  }
  */

  /*
  handlePepron = (id: string, botResponse: BotResponse) => {
    // Add Zendesk script to page
    let key = botResponse.action_input.key
    let email = botResponse.action_input.email
    let name = botResponse.action_input.name
    let message = botResponse.action_input.message
    this.getToken(key, name, email, message)
    /*
    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            suppress: false,
            jwtFn: function(callback) {
              fetch('https://server1.pepron.com/espoonasunnot/chattoken.php?email='+email+'&name='+name).then(function(res) {
                res.text().then(function(jwt) {
                  callback(jwt)
                });
              });
            }
          }
        }
      }
    }
    setTimeout(() => {
        this.closeChat()
        this.loadZendesk(key, botResponse.action_input.message, email)
    }, botResponse.action_input.delay)
  }
    */

   prepareZendesk = (key: string, name: string, email: string) => {
        window.zESettings = {
            webWidget: {
              authenticate: {
                chat: {
                  suppress: false,
                  jwtFn: function(callback) {
                    fetch('https://server1.pepron.com/espoonasunnot/chattoken.php?email='+email+'&name='+name).then(function(res) {
                      res.text().then(function(jwt) {
                        callback(jwt)
                      })
                    })
                  }
                }
              }
            }
        }
        this.loadZendesk(key)
   }


  loadZendesk = (key: string) => {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key='+key
    document.getElementsByTagName('head')[0].appendChild(script)
    this.closeChat()
    setTimeout(this.addStartListener, 1000)
  }

  addStartListener = () => {
    if (typeof(window.zE) == "undefined") {
      setTimeout(this.addStartListener, 1000)
    } else {
      window.zE('webWidget:on', 'chat:start', function() {
        fetch('https://server1.pepron.com/espoonasunnot/storebotchat.php?token='+window.zEChatToken, {
          method: 'POST',
          body: window.gjMessage,
          mode: 'no-cors'
        }).then(res => {
          console.log('Store chat:', res)
        }).catch(function(error) {
          console.log('Request failed', error)
        })
      })
    }
  }

  getToken = (key: string, name: string, email: string, message: string) => {
    let t = this
    fetch('https://server1.pepron.com/espoonasunnot/chattoken.php?email='+email+'&name='+name).then(function(res){
        res.text().then(function(jwt) {
            //console.log('Chat token:', jwt);
            window.zEChatToken = jwt;
            window.gjMessage = message;
            t.prepareZendesk(key, name, email);
        })
    })
  }

  handleCloseChat = () => {
    this.chatHasEnded = true;
  }

  handleChangeBot = (id: string, botResponse: BotResponse) => {
    this.nextResponseState = '';
    this.updateChatHistory(id, {...botResponse, action: ''});

    const waitBotToFinishInterval = setInterval(() => {
      if (!this.botIsTyping) {
        clearInterval(waitBotToFinishInterval);

        if (typeof botResponse.action_input !== 'undefined' && botResponse.action_input.target && typeof botResponse.action_input.target !== 'undefined') {
          const target = botResponse.action_input.target;
          this.updateAttribute('connectorId', target);
          localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_bot_id', target);
          this.addIntroResponse();
        }
      }
    }, 100);
  };

  handleTransferBot = (id: string, botResponse: BotResponse) => {
    // In case of transfer action, change connector id to what is supplied in response.
    this.nextResponseState = '';
    this.updateChatHistory(id, botResponse);
    if (typeof botResponse.action_input.config !== 'undefined') {
      const config = JSON.parse(botResponse.action_input.config);

      // If we get a new target from the config, set it as our current bot
      if (config.target && typeof config.target !== 'undefined') {
        this.updateAttribute('connectorId', config.target);
        localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_bot_id', config.target);
      }
      // if there is a state, then we get a follow up response from this new bot
      if (typeof config.state !== 'undefined' && config.state.trim().length > 0) {
        this.getBotResponse({...this.transferMessage, state: config.state});
      } else {
        this.getBotResponse(this.transferMessage);
      }
    }
  };

  handleFeedbackButtons = async (id: string, botResponse: BotResponse) => {
    //Get feedback buttons to be rendered on the front end
    const actionInput = [];
    const nextState = botResponse.action_input.state;

    for (let button of botResponse.action_input.buttons) {
      actionInput.push({
        type: 'button',
        label: `${button.label}`,
        state: nextState,
      });
    }

    this.updateChatHistory(id, botResponse, actionInput);
    this.disableInput();
  };

  handleSendEmail = (id: string, botResponse: BotResponse) => {
    this.updateChatHistory(id, botResponse);
    this.nextResponseState = '';
    // Go through each variable
    const config = JSON.parse(botResponse.action_input.config);

    const message = `
        <html>
          ${
      Object.keys(this.collectInformation).map((attribute) => (
        `<strong>${attribute}</strong>: ${this.collectInformation[attribute]}`
      )).join('<br />')
    }
        </html>
      `;

    let email = {message};

    if (config && config.subject) {
      email = {
        ...config,
        ...email,
      };
    }

    this.collectNextInformation = false;
    this.collectInformation = {};
    this.apiClient.postUserEmail(email);
  };

  handleNextResponse = (id: string, botResponse: BotResponse) => {
    //We'll need this when user answers bot questions by typing them
    this.nextResponseState = botResponse.action_input.state;
    this.updateChatHistory(id, botResponse);

    //Enable input if disabled
    this.enableInput();
  };

  handleFollowUpResponse = async (id: string, botResponse: BotResponse) => {
    //When handling follow_up, we don't need nextResponseState
    this.nextResponseState = '';
    this.updateChatHistory(id, botResponse);
    try {
      const responseWithState = await this.apiClient.getResponseWithState(botResponse.action_input.state);
      //Delaying follow up message
      this.randomizeResponseBubble(responseWithState);
      this.addBotResponseToHistory(responseWithState);
    } catch (error) {
      throw Error(`Something happened in addFollowUpResponse action: ${error}`);
    }

    //Enable input if disabled
    this.enableInput();
  };

  handleCollectInformationResponse = async (id: string, botResponse: BotResponse) => {
    //When handling collect_information response we need to parse action data
    const config = JSON.parse(botResponse.action_input.config);

    if (config && config.state !== undefined) {
      this.nextResponseState = config.state;
    }

    if (config.variable) {
      this.collectNextInformation = config.variable;
    }

    this.updateChatHistory(id, botResponse);
    //Enable input if disabled
    this.enableInput();
  };

  handleButtonsResponse = (id: string, botResponse: BotResponse) => {
    const actionInput = botResponse.action_input.buttons;
    this.updateChatHistory(id, botResponse, actionInput);
    this.disableInput();
  };

  handleInputValidation = (id: string, botResponse: BotResponse) => {
    // input_validation has object [{ keyword: '', state: '' }] in action_input.datalist
    const actionInput = botResponse.action_input.buttons;
    this.updateChatHistory(id, botResponse, actionInput);
    this.nextResponseKeyword = actionInput;
    this.enableInput();
  };

  disableInput = () => {
    if (get(this.chatAttributes, 'disableChatOnButtons') === 'true') {
      this.inputIsDisabled = get(this.chatAttributes, 'disableChatOnButtons') === 'true';
    }
  };

  enableInput = () => {
    if (this.inputIsDisabled) {
      this.inputIsDisabled = false;
    }
  };

  updateChatHistory(id: string, botResponse: BotResponse, actionInput?: Array<?Object>) {
    this.handleBotResponseBubble(botResponse);

    let i = 0;

    if (this.chatHistory.length === 0) {
      this.chatHistory.push({
        id,
        action: botResponse.action,
        bubble: [botResponse.bubble[0]],
        indexInConversation: botResponse.indexInConversation,
        actionInput: botResponse.bubble.length === 1 ? (actionInput ? actionInput : (botResponse.action_input ? botResponse.action_input : undefined)) : undefined,
        showFeedback: true,
      });

      if (botResponse.bubble.length === 1) {
        addChatHistoryToSessionStorage(this.chatAttributes.connectorId, this.chatHistory);
        return;
      }

      i = 1;
    }

    const delay = this.getDelay();
    this.setBotTypingOn();

    this.intervalId = setInterval(() => {
      if (i === 0) {
        this.chatHistory.push({
          id,
          action: botResponse.action,
          bubble: [botResponse.bubble[0]],
          indexInConversation: botResponse.indexInConversation,
          showFeedback: true,
        });
      }

      const chatHistory = this.chatHistory.map(chat => {
          if (chat.id === id) {
            if (i !== 0) {
              chat.bubble.push(botResponse.bubble[i]);
            }

            if (chat.bubble.length === botResponse.bubble.length) {
              chat.actionInput = actionInput ? actionInput : (botResponse.action_input ? botResponse.action_input : undefined);
            }
          }

          return chat;
        },
      );

      this.chatHistory = chatHistory;
      addChatHistoryToSessionStorage(this.chatAttributes.connectorId, chatHistory);

      i++;

      if (botResponse.bubble.length - 1 < i) {
        this.setBotTypingOff();
        clearInterval(this.intervalId);
      }
    }, delay);
  }

  hideFeedbackFromChatHistory(chatId: string) {
    const chatHistory = this.chatHistory.map(chat => {
        if (chat.id === chatId) {
          chat.showFeedback = false;
        }

        return chat;
      },
    );

    this.chatHistory = chatHistory;
    addChatHistoryToSessionStorage(this.chatAttributes.connectorId, chatHistory);
  }

  hideResponseOptionsFromChatHistory(id: string) {
    const chatHistory = this.chatHistory.map((message) => {
      if (message.id === id) {
        message.hide = true;
      }

      return message;
    });

    this.chatHistory = chatHistory;
    addChatHistoryToSessionStorage(this.chatAttributes.connectorId, chatHistory);
  }

  openChat = (): void => {
    if (!localStorage.getItem(this.chatAttributes.connectorId + '_chatHistory') && this.chatAttributes.connectorId !== 'BUILDER') {
      this.addIntroResponse();
    }

    localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_chat_open', 'true');
    localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_chat_opened_before', 'true');

    this.chatIsActive = true;
    this.chatIsOpenedBefore = true;
    this.closeWelcomeBubble();
    this.pushToGTM('getjenny_chat_open');
  };

  openWelcomeBubble = (): void => {
    const welcomeBubbleAlreadyOpened = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_welcome_opened');
    const chatOpen = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_chat_open');
    const chatOpenedBefore = localStorage.getItem(this.chatAttributes.connectorId + '_getjenny_chat_opened_before');

    if (!welcomeBubbleAlreadyOpened && !chatOpen && !chatOpenedBefore) {
      localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_welcome_opened', 'true');
      this.welcomeBubbleVisibility = 'visible';
    }
  };

  closeWelcomeBubble = (): void => {
    this.welcomeBubbleVisibility = 'hidden';
  };

  closeChat = (): void => {
    localStorage.setItem(this.chatAttributes.connectorId + '_getjenny_chat_open', 'false');
    this.chatIsActive = false;
    this.pushToGTM('getjenny_chat_close');
  };

  toggleHighContrastActive = (): void => {
    this.isHighContrastActive = !this.isHighContrastActive;
  };

  setLargerFontSize = (): void => {
    if (!this.isLargerFontMode) {
      this.isLargerFontMode = true;
      this.chatAttributes.inputFontSize = parseInt(this.chatAttributes.inputFontSize) + 4;
      this.chatAttributes.buttonFontSize = parseInt(this.chatAttributes.buttonFontSize) + 4;
    }
  };

  setSmallerFontSize = (): void => {
    if (this.isLargerFontMode) {
      this.isLargerFontMode = false;
      this.chatAttributes.inputFontSize = parseInt(this.chatAttributes.inputFontSize) - 4;
      this.chatAttributes.buttonFontSize = parseInt(this.chatAttributes.buttonFontSize) - 4;
    }
  };

  getProcessedText(text) {
    const pieces = text.split(' ');
    let processedText = '';
    let processing = false;

    for (let i = 0; i < pieces.length; i++) {
      if (pieces[i] === '[link' || pieces[i] === '[video' || pieces[i] === '[image') {
        processing = true;
        let tag = '';

        for (let j = i; j < pieces.length; j++) {
          tag += pieces[j] + ' ';

          if (pieces[j] === ']') {
            break;
          }
        }

        tag = tag.trim();
        let elementToAdd = '';

        if (tag.indexOf('[link') > -1) {
          elementToAdd = '<a target="_blank" href="' + tag.match('src=\'(.*?)\'')[1] + '">' + tag.match('text=\'(.*?)\'')[1] + '</a>';
        } else if (tag.indexOf('[video') > -1) {
          elementToAdd = '<div class="iframe-container"><iframe width="560" height="315" src="' + tag.match('src=\'(.*?)\'')[1] + '" frameborder="0" allow="autoplay" allowfullscreen></iframe></div>';
        } else if (tag.indexOf('[image') > -1) {
          elementToAdd = '<img src="' + tag.match('src=\'(.*?)\'')[1] + '" alt="Image message" class="responsive-image">';
        }

        processedText += tag.replace(/\[(.*?)\]/, elementToAdd) + ' ';
      }

      if (!processing) {
        processedText += pieces[i] + ' ';
      } else if (processing && pieces[i] === ']') {
        processing = false;
      }
    }

    return processedText.trim();
  }

  handleBotResponseBubble = (botResponse: BotResponse) => {
    const {bubble} = botResponse;
    //Handling a case where bot bubble double enter has already been processed
    if (typeof bubble === 'string') {
      botResponse.bubble = getBubble(bubble);
      return botResponse;
    } else {
      return botResponse;
    }
  };

  randomizeResponseBubble = (botResponse: BotResponse) => {
    let {bubble} = botResponse;
    if (/\|/.test(bubble)) {
      const replies = bubble.split(/\|/);
      bubble = replies[Math.floor(Math.random() * replies.length)];
    }
    botResponse.bubble = bubble;
    return botResponse;
  };

  // Google Tag Manager
  pushToGTM = (eventName: string, option: string) => {
    if (window['dataLayer']) {
      const event = option ? {'event': eventName, 'option': option} : {'event': eventName};
      window['dataLayer'].push(event);
    }
  };

  sendFeedback = async (indexInConversation, score) => {
    return await this.apiClient.sendFeedback(indexInConversation, score);
  };
}

decorate(ApplicationStore, {
  chatAttributes: observable,
  collectInformation: observable,
  collectNextInformation: observable,
  embedded: observable,
  chatIsActive: observable,
  isHighContrastActive: observable,
  isLargerFontMode: observable,
  chatIsOpenedBefore: observable,
  botIsTyping: observable,
  chatHistory: observable,
  nextResponseState: observable,
  transferMessage: observable,
  inputIsDisabled: observable,
  nextResponseKeyword: observable,
  welcomeBubbleVisibility: observable,

  sendFeedback: action,
  getProcessedText: action,
  setSmallerFontSize: action,
  closeChat: action,
  toggleHighContrastActive: action,
  setLargerFontSize: action,
  hideResponseOptionsFromChatHistory: action,
  openChat: action,
  openWelcomeBubble: action,
  closeWelcomeBubble: action,
  updateChatHistory: action,
  hideFeedbackFromChatHistory: action,
  handleButtonsResponse: action,
  handleInputValidation: action,
  disableInput: action,
  enableInput: action,
  handleCollectInformationResponse: action,
  handleFollowUpResponse: action,
  handleNextResponse: action,
  handleSendEmail: action,
  handleFeedbackButtons: action,
  handleCloseChat: action,
  handleTransferBot: action,
  getNextBubbleResponse: action,
  addBotResponseToHistory: action,
  setBotTypingOn: action,
  setBotTypingOff: action,
  addUserResponseToHistory: action,
  getBotResponse: action,
  setChatAttributes: action,
  addIntroResponse: action,
  getLinkPreview: action,
  updateAttribute: action,
  initLocalStorage: action,
  init: action,
});
