import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          src: 'Source',
          srcTooltip: 'Define where widget script is fetched from.',
          connectorId: 'Connector ID',
          identifier: 'Identifier',
          title: 'Header title',
          titleAlignment: 'Title alignment',
          customerLogoUrl: 'Header logo URL',
          accentColor: 'Accent color',
          accentColorTooltip: 'Define the color of buttons in chat and send button.',
          contactBackgroundColor: 'Contact background color',
          contactBackgroundColorTooltip: 'Define the color of contact\'s chat bubble.',
          buttonLabel: 'Button label',
          buttonLabelTooltip: 'Text of \'send\' button. If left empty uses defined send image.',
          placeholder: 'Message box text',
          placeholderTooltip: 'Define what is written on message box when customer is not typing a message.',
          showGetjennyReference: 'Footer reference (Y/N)',
          showGetjennyReferenceTooltip: 'Define if a particular text / URL is shown on the bottom of the widget.',
          disableChatOnButtons: 'Disable/enable writing with buttons',
          disableChatOnButtonsTooltip: 'Disable or enable writing on message box when widget is displaying buttons.',
          chatLocationHorizontal: 'Chat location (horizontal)',
          chatLocationHorizontalTooltip: 'Define if chat widget (non-embedded) is on the left or right hand side of the browser.',
          chatLocationVertical: 'Chat location (vertical)',
          chatLocationVerticalTooltip: 'Define if chat widget (non-embedded) is on the top or on the bottom of the page.',
          chatMarginHorizontal: 'Chat margin (horizontal)',
          chatMarginHorizontalTooltip: 'Margin from selected side.',
          chatMarginVertical: 'Chat margin (vertical)',
          chatMarginVerticalTooltip: 'Margin from top or bottom.',
          openChatOnLoad: 'Chat default open/closed',
          openChatOnLoadTooltip: 'Define if the non-embedded chat widget will be automatically opened or closed after page load.',
          themeColor: 'Header color',
          titleTextColor: 'Header title color',
          footerColor: 'Footer reference color',
          targetElement: 'Target element',
          targetElementTooltip: 'Define the name of the target element for an embedded widget. Empty field will configure a standard widget.',
          chatCorners: 'Chat element corners',
          chatCornersTooltip: 'Define if the elements in the widget have round or rectangular corners (e.g. 10)',
          widgetShape: 'Widget shape',
          widgetShapeTooltip: 'Define if the widget corners are round or rectangular (e.g. 10 10 0 10)',
          bubbleCorners: 'Chat bubble shape',
          bubbleCornersTooltip: 'Define if the widget corners are round or rectangular (e.g. 10 10 0 10)',
          useChatIcon: 'Use chat icon (Y/N)',
          useChatIconTooltip: 'Define if non-embedded widget uses a defined chat icon when closed.',
          chatIconUrl: 'Chat icon URL',
          chatIconPosition: 'Chat icon position',
          chatIconPositionTooltip: 'Define chat icon position.',
          chatIconShape: 'Chat icon shape',
          chatIconShapeTooltip: 'Define if the chat icon\'s corners are round or rectangular (e.g. 10 10 0 10)',
          showWidgetBorders: 'Show widget borders',
          showWidgetBordersTooltip: 'Define if widget has borders or no.',
          shadows: 'Shadows',
          shadowsTooltip: 'Define if and what kind of shadow widget elements have.',
          backgroundColor: 'Background color',
          backgroundColorTooltip: 'Define widget background color.',
          disclaimerText: 'Disclaimer text',
          disclaimerBackgroundColor: 'Disclaimer background color',
          timeout: 'Timeout',
          timeoutTooltip: 'Period in seconds after which old chat history is cleared. Default is 5 minutes.',
          headerExists: 'Header exists (Y/N)',
          headerExistsTooltip: 'Define if widget has header element.',
          fixedSize: 'Fixed size',
          fixedSizeTooltip: 'Define if embedded widget size is fixed or dynamic.',
          width: 'Widget width',
          height: 'Widget height',
          zIndex: 'Z-index',
          footerUrl: 'Footer URL',
          footerUrlTooltip: 'Define the URL where footer text directs.',
          footerText: 'Footer text',
          footerTextTooltip: 'Define the text that is displayed at the footer of the widget.',
          inputFont: 'Conversation font',
          inputFontSize: 'Conversation font size',
          widgetFont: 'Header font',
          widgetFontSize: 'Header font size',
          buttonFont: 'Button font',
          buttonFontSize: 'Button font size',
          backgroundTransparency: 'Background transparency (0-1)',
          backgroundTransparencyTooltip: 'Allows to define widget background transparency.',
          backgroundImageUrl: 'Background image URL',
          backgroundImageUrlTooltip: 'Allows the option to define widget background picture.',
          backgroundPosition: 'Background position',
          backgroundSize: 'Background size',
          backgroundRepeat: 'Background repeat',
          botIconUrl: 'Bot icon URL',
          botIconUrlTooltip: 'Define from which URL the bot icon is retrieved.',
          botIconWidth: 'Bot icon size (width)',
          botIconHeight: 'Bot icon size (height)',
          chatIconWidth: 'Chat icon size (width)',
          chatIconHeight: 'Chat icon size (height)',
          enableFeedback: 'Enable message feedback',
          enableFeedbackTooltip: 'Allows to collect thumbs up / thumbs down feedback for chatbot messages.',
          likeOnColor: 'Feedback thumbs color when voted',
          likeOffColor: 'Feedback thumbs color when before voting',
          botReplyDelay: 'Bot response message level delay',
          botReplyDelayTooltip: 'Define bot response message level delay in seconds',
          welcomeText: 'Welcome text',
          welcomeTextTooltip: 'Text showed to user before chat begins',
          botIconBorderRadius: 'Bot icon border radius',
          customerLogoWidthPercentage: 'Customer logo width percentage',
          customerLogoSideMargins: 'Customer logo side margins',
          sendIconUrl: 'Send icon URL',
          autoOpenTime: 'Automatic opening time',
          autoOpenTimeTooltip: 'Period in seconds after which chat widget will open automatically.',
          welcomeTextTime: 'Show welcome text delay',
          welcomeTextTimeTooltip: 'Period in seconds after which chat widget will show welcome message.',
          enableHighContrastOption: 'Enable/Disable high contrast mode',
          enableHighContrastOptionTooltip: 'Enable/Disable high contrast mode.',
          enableFontSizeOption: 'Enable/Disable font size toggler',
          enableFontSizeOptionTooltip: 'Enable/Disable font size increasing and decreasing.',
          reclickableButtons: 'Buttons are re-clickable',

          screenReaderClose: 'Close chat window',
          screenReaderCloseInfo: 'Close info box',
          screenReaderWelcomeBubble: 'Close welcome message',
          screenReaderSmallFont: 'Decrease font size',
          screenReaderLargeFont: 'Increase font size',
          screenReaderHighContrastMode: 'High contrast mode on/off',
          screenReaderLogo: 'Logo',
          screenReaderInput: 'Your message',
        },
      },
      fi: {
        translations: {
          src: 'Source',
          srcTooltip: 'Define where widget script is fetched from.',
          connectorId: 'Connector ID',
          identifier: 'Identifier',
          title: 'Header title',
          titleAlignment: 'Title alignment',
          customerLogoUrl: 'Header logo URL',
          accentColor: 'Accent color',
          accentColorTooltip: 'Define the color of buttons in chat and send button.',
          buttonLabel: 'Button label',
          buttonLabelTooltip: 'Text of \'send\' button. If left empty uses defined send image.',
          placeholder: 'Message box text',
          placeholderTooltip: 'Define what is written on message box when customer is not typing a message.',
          showGetjennyReference: 'Footer reference (Y/N)',
          showGetjennyReferenceTooltip: 'Define if a particular text / URL is shown on the bottom of the widget.',
          disableChatOnButtons: 'Disable/enable writing with buttons',
          disableChatOnButtonsTooltip: 'Disable or enable writing on message box when widget is displaying buttons.',
          chatLocationHorizontal: 'Chat location (horizontal)',
          chatLocationHorizontalTooltip: 'Define if chat widget (non-embedded) is on the left or right hand side of the browser.',
          chatLocationVertical: 'Chat location (vertical)',
          chatLocationVerticalTooltip: 'Define if chat widget (non-embedded) is on the top or on the bottom of the page.',
          chatMarginHorizontal: 'Chat margin (horizontal)',
          chatMarginHorizontalTooltip: 'Margin from selected side.',
          chatMarginVertical: 'Chat margin (vertical)',
          chatMarginVerticalTooltip: 'Margin from top or bottom.',
          openChatOnLoad: 'Chat default open/closed',
          openChatOnLoadTooltip: 'Define if the non-embedded chat widget will be automatically opened or closed after page load.',
          themeColor: 'Header color',
          titleTextColor: 'Header title color',
          footerColor: 'Footer reference color',
          targetElement: 'Target element',
          targetElementTooltip: 'Define the name of the target element for an embedded widget. Empty field will configure a standard widget.',
          chatCorners: 'Chat element corners',
          chatCornersTooltip: 'Define if the elements in the widget have round or rectangular corners (e.g. 10)',
          widgetShape: 'Widget shape',
          widgetShapeTooltip: 'Define if the widget corners are round or rectangular (e.g. 10 10 0 10)',
          bubbleCorners: 'Chat bubble shape',
          bubbleCornersTooltip: 'Define if the widget corners are round or rectangular (e.g. 10 10 0 10)',
          useChatIcon: 'Use chat icon (Y/N)',
          useChatIconTooltip: 'Define if non-embedded widget uses a defined chat icon when closed.',
          chatIconUrl: 'Chat icon URL',
          chatIconPosition: 'Chat icon position',
          chatIconPositionTooltip: 'Define chat icon position.',
          chatIconShape: 'Chat icon shape',
          chatIconShapeTooltip: 'Define if the chat icon\'s corners are round or rectangular (e.g. 10 10 0 10)',
          showWidgetBorders: 'Show widget borders',
          showWidgetBordersTooltip: 'Define if widget has borders or no.',
          shadows: 'Shadows',
          shadowsTooltip: 'Define if and what kind of shadow widget elements have.',
          backgroundColor: 'Background color',
          backgroundColorTooltip: 'Define widget background color.',
          disclaimerText: 'Disclaimer text',
          disclaimerBackgroundColor: 'Disclaimer background color',
          timeout: 'Timeout',
          timeoutTooltip: 'Period in seconds after which old chat history is cleared. Default is 5 minutes.',
          headerExists: 'Header exists (Y/N)',
          headerExistsTooltip: 'Define if widget has header element.',
          fixedSize: 'Fixed size',
          fixedSizeTooltip: 'Define if embedded widget size is fixed or dynamic.',
          width: 'Widget width',
          height: 'Widget height',
          footerUrl: 'Footer URL',
          footerUrlTooltip: 'Define the URL where footer text directs.',
          footerText: 'Footer text',
          footerTextTooltip: 'Define the text that is displayed at the footer of the widget.',
          inputFont: 'Conversation font',
          inputFontSize: 'Conversation font size',
          widgetFont: 'Header font',
          widgetFontSize: 'Header font size',
          buttonFont: 'Button font',
          buttonFontSize: 'Button font size',
          backgroundTransparency: 'Background transparency (0-1)',
          backgroundTransparencyTooltip: 'Allows to define widget background transparency.',
          backgroundImageUrl: 'Background image URL',
          backgroundImageUrlTooltip: 'Allows the option to define widget background picture.',
          backgroundPosition: 'Background position',
          backgroundSize: 'Background size',
          backgroundRepeat: 'Background repeat',
          botIconUrl: 'Bot icon URL',
          botIconUrlTooltip: 'Define from which URL the bot icon is retrieved.',
          botIconWidth: 'Bot icon size (width)',
          botIconHeight: 'Bot icon size (height)',
          chatIconWidth: 'Chat icon size (width)',
          chatIconHeight: 'Chat icon size (height)',
          enableFeedback: 'Enable message feedback',
          enableFeedbackTooltip: 'Allows to collect thumbs up / thumbs down feedback for chatbot messages.',
          likeOnColor: 'Feedback thumbs color when voted',
          likeOffColor: 'Feedback thumbs color when before voting',
          botReplyDelay: 'Bot response message level delay',
          botReplyDelayTooltip: 'Define bot response message level delay in seconds',
          welcomeText: 'Welcome text',
          welcomeTextTooltip: 'Text showed to user before chat begins',
          botIconBorderRadius: 'Bot icon border radius',
          customerLogoWidthPercentage: 'Customer logo width percentage',
          customerLogoSideMargins: 'Customer logo side margins',
          sendIconUrl: 'Send icon URL',
          autoOpenTime: 'Automatic opening time',
          autoOpenTimeTooltip: 'Period in seconds after which chat widget will open automatically.',
          welcomeTextTime: 'Show welcome text delay',
          welcomeTextTimeTooltip: 'Period in seconds after which chat widget will show welcome message.',
          enableHighContrastOption: 'Enable/Disable high contrast mode',
          enableHighContrastOptionTooltip: 'Enable/Disable high contrast mode.',
          enableFontSizeOption: 'Enable/Disable font size toggler',
          enableFontSizeOptionTooltip: 'Enable/Disable font size increasing and decreasing.',
          reclickableButtons: 'Buttons are re-clickable',

          screenReaderClose: 'Sulje chatti-ikkuna',
          screenReaderCloseInfo: 'Sulje info-laatikko',
          screenReaderWelcomeBubble: 'Sulje tervetuloa-viesti',
          screenReaderSmallFont: 'Pienennä tekstin kokoa',
          screenReaderLargeFont: 'Suurenna tekstin kokoa',
          screenReaderHighContrastMode: 'Korkean kontrastin tila päälle/pois',
          screenReaderLogo: 'Logo',
          screenReaderInput: 'Sinun viestisi',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
