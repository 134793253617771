import root from 'react-shadow/styled-components';
import React, { Component, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ChatContent from './components/chatContent/ChatContent';
import { ChatIcon, Container, WelcomeBubble } from './appStyles';
import { compose } from 'recompose';
//import ReactGA from 'react-ga';
import { Store } from './components/common/types';

import './i18n';
import i18next from 'i18next';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { HeaderButton } from './components/header/headerStyles';

interface Props {
  store: Store;
}

class App extends Component<Props> {
  ConstructorElement = null;

  constructor() {
    super();

    if (process.env.REACT_APP_SHOW_BUILDER === 'true') {
      this.ConstructorElement = React.lazy(() => import('./components/constructor/Constructor'));
    }

    const userLang = navigator.language || navigator.userLanguage;
    if (userLang === 'fi-FI') {
      i18next.changeLanguage('fi').then();
    }

    this.escFunction = this.escFunction.bind(this);
  }

  toggleChatOpen = (event: Event) => {
    if (this.props.store.embedded) {
      event.stopPropagation();
      return;
    }

    if (!this.props.store.chatIsActive) {
      this.props.store.openChat();
    } else {
      this.props.store.closeChat();
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.toggleChatOpen();
    }
  };

  welcomeClicked = () => {
    this.props.store.openChat();
    this.props.store.closeWelcomeBubble();
  };

  handleChatClose = (event: Event) => {
    event.stopPropagation();
    this.props.store.closeChat();
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.store.closeChat();
    }
  };

  componentDidMount() {
    const { environment } = this.props.store;

    if (!this.props.store.embedded) {
      document.addEventListener('keydown', this.escFunction, false);
    }

    if (environment !== 'development') {
      //ReactGA.initialize('UA-120070835-2');
      //ReactGA.pageview(window.location.href);
      //ReactGA.event({
      //  category: 'Versions',
      //  action: 'Version',
      //  label: packageJson.version,
      //});
    }

    const {
      autoOpenTime,
      openChatOnLoad,
      welcomeTextTime,
      targetElement,
      welcomeText,
      connectorId,
    } = this.props.store.chatAttributes;

    if (openChatOnLoad === 'true' || (autoOpenTime && autoOpenTime > 0)) {
      const timeout =
        openChatOnLoad !== 'true' && autoOpenTime && autoOpenTime > 0 && !this.props.store.embedded
          ? autoOpenTime
          : 0;
      setTimeout(() => {
        const { chatIsOpenedBefore } = this.props.store;
        const chatOpenedBeforeFromLocalStorage = localStorage.getItem(
          connectorId + '_getjenny_chat_opened_before',
        );

        if (!chatIsOpenedBefore && !chatOpenedBeforeFromLocalStorage) {
          this.props.store.openChat();
        }
      }, timeout * 1000);
    }

    if (openChatOnLoad !== 'true' || !autoOpenTime || autoOpenTime === 0) {
      if (welcomeText && welcomeTextTime && !targetElement) {
        setTimeout(() => {
          const { chatIsOpenedBefore } = this.props.store;

          if (!chatIsOpenedBefore) {
            this.props.store.openWelcomeBubble();
          }
        }, welcomeTextTime * 1000);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  render() {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const supportsShadowDOMV1 = !!HTMLElement.prototype.attachShadow;
    const { chatAttributes, environment, showBuilder } = this.props.store;

    const theme = {
      color: chatAttributes.themeColor,
      accentColor: chatAttributes.accentColor,
      titleTextColor: chatAttributes.titleTextColor,
      contactBackgroundColor: chatAttributes.contactBackgroundColor,
    };

    const iOSVersion = this.checkIOSVersion();
    const browserSupported = iOSVersion >= 10 || iOSVersion === 0;

    return (
      browserSupported && (
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <Suspense fallback={<div>Loading....</div>}>
              {(environment === 'development' || showBuilder) && this.ConstructorElement && (
                <this.ConstructorElement />
              )}
              {!isIE11 && supportsShadowDOMV1 && root && root.pendingInteractionMap ? (
                <root.div className="shadow"> {this.getContents()}</root.div>
              ) : (
                this.getContents()
              )}
            </Suspense>
          </React.Fragment>
        </ThemeProvider>
      )
    );
  }

  checkIOSVersion = function () {
    const agent = window.navigator.userAgent,
      start = agent.indexOf('OS');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  };

  hideWelcomeBubble = () => {
    this.props.store.closeWelcomeBubble();
  };

  getContents() {
    const {
      chatIsActive,
      chatAttributes,
      embedded,
      isHighContrastActive,
      welcomeBubbleVisibility,
    } = this.props.store;

    if (embedded) {
      this.chatIsActive = true;
    }

    const {
      chatLocationHorizontal,
      chatCorners,
      headerExists,
      fixedSize,
      width,
      height,
      widgetFont,
      widgetFontSize,
      backgroundTransparency,
      backgroundImageUrl,
      backgroundPosition,
      backgroundSize,
      backgroundRepeat,
      shadows,
      backgroundColor,
      chatIconPosition,
      widgetShape,
      chatIconUrl,
      customerLogoUrl,
      botIconBorderRadius,
      showWidgetBorders,
      titleAlignment,
      customerLogoWidthPercentage,
      customerLogoSideMargins,
      sendIconUrl,
      enableHighContrastOption,
      chatMarginHorizontal,
      chatLocationVertical,
      chatMarginVertical,
      chatIconShape,
      enableFontSizeOption,
      themeColor,
      useChatIcon,
      reclickableButtons,
      chatIconHeight,
      chatIconWidth,
      welcomeText,
      bubbleCorners,
      zIndex,
    } = chatAttributes;

    const widgetCorners = this.splitCornersToArray(widgetShape);
    const chatIconCorners = this.splitCornersToArray(chatIconShape);
    const bubbleCornersArrayTemp = this.splitCornersToArray(bubbleCorners);
    const bubbleCornersArray = [];

    const welcomeBubblePosition = this.getWelcomeBubblePosition(
      useChatIcon,
      chatIconPosition,
      chatLocationVertical,
      chatLocationHorizontal,
      chatIconHeight,
      chatIconWidth,
      chatMarginHorizontal,
      fixedSize,
      width,
      bubbleCornersArray,
      bubbleCornersArrayTemp,
    );

    return (
      <div>
        <ChatIcon
          embedded={embedded}
          useChatIcon={useChatIcon}
          logo={chatIconUrl.length > 0 ? chatIconUrl : customerLogoUrl}
          onClick={this.toggleChatOpen}
          chatLocationHorizontal={chatLocationHorizontal}
          chatIconPosition={chatIconPosition}
          chatIconShapeTopLeft={chatIconCorners[0]}
          chatIconShapeTopRight={chatIconCorners[1]}
          chatIconShapeBottomLeft={chatIconCorners[2]}
          chatIconShapeBottomRight={chatIconCorners[3]}
          chatIconWidth={chatIconWidth}
          chatIconHeight={chatIconHeight}
          aria-label={chatAttributes.title}
          zIndex={zIndex}
        />

        <WelcomeBubble
          embedded={embedded}
          width={width}
          fixedSize={fixedSize}
          cornerShapeTopLeft={bubbleCornersArray[0]}
          cornerShapeTopRight={bubbleCornersArray[1]}
          cornerShapeBottomLeft={bubbleCornersArray[2]}
          cornerShapeBottomRight={bubbleCornersArray[3]}
          themeColor={themeColor}
          welcomeBubblePosition={welcomeBubblePosition}
          welcomeBubbleVisibility={welcomeBubbleVisibility}
          zIndex={zIndex}
        >
          <div className="text" onClick={this.welcomeClicked}>
            {welcomeText}
          </div>
          <HeaderButton
            aria-label={i18n.t('screenReaderCloseWelcomeBubble')}
            className="close-btn"
            onClick={this.hideWelcomeBubble}
            tabIndex={0}
          >
            <FontAwesomeIcon icon={faTimes} />
          </HeaderButton>
        </WelcomeBubble>

        <Container
          embedded={embedded}
          chatIsActive={chatIsActive}
          chatLocationHorizontal={chatLocationHorizontal}
          chatLocationVertical={chatLocationVertical}
          chatCorners={chatCorners}
          fixedSize={fixedSize}
          width={width}
          height={height}
          backgroundSize={backgroundSize}
          backgroundPosition={backgroundPosition}
          backgroundRepeat={backgroundRepeat}
          backgroundTransparency={backgroundTransparency}
          backgroundImageUrl={backgroundImageUrl}
          useChatIcon={useChatIcon}
          shadows={shadows}
          backgroundColor={backgroundColor}
          widgetShapeTopLeft={widgetCorners[0]}
          widgetShapeTopRight={widgetCorners[1]}
          widgetShapeBottomLeft={widgetCorners[2]}
          widgetShapeBottomRight={widgetCorners[3]}
          showWidgetBorders={showWidgetBorders}
          isBlackAndWhite={isHighContrastActive}
          chatMarginHorizontal={chatMarginHorizontal}
          chatMarginVertical={chatMarginVertical}
          zIndex={zIndex}
        >
          <Header
            headerExists={JSON.parse(headerExists)}
            title={chatAttributes.title}
            customerLogoUrl={customerLogoUrl}
            customerLogoSideMargins={customerLogoSideMargins}
            store={this.props.store}
            onClick={this.toggleChatOpen}
            handleKeyDown={this.handleKeyDown}
            onClose={this.handleChatClose}
            embedded={embedded}
            chatCorners={chatCorners}
            widgetShape={widgetShape}
            widgetFont={widgetFont}
            widgetFontSize={widgetFontSize}
            shadows={shadows}
            fixedSize={fixedSize}
            width={width}
            titleAlignment={titleAlignment}
            customerLogoWidthPercentage={customerLogoWidthPercentage}
            enableHighContrastOption={enableHighContrastOption}
            chatLocationVertical={chatLocationVertical}
            enableFontSizeOption={enableFontSizeOption}
            themeColor={themeColor}
          />

          {JSON.parse(chatIsActive) && (
            <ChatContent
              chatCorners={chatCorners}
              headerExists={headerExists}
              shadows={shadows}
              chatIsActive={chatIsActive}
              botIconBorderRadius={botIconBorderRadius}
              widgetShapeTopRight={widgetCorners[1]}
              reclickableButtons={reclickableButtons}
              sendFeedback={(indexInConversation, score) =>
                this.props.store.sendFeedback(indexInConversation, score)
              }
              hideFeedbackFromChatHistory={(chatId) =>
                this.props.store.hideFeedbackFromChatHistory(chatId)
              }
            />
          )}

          {JSON.parse(chatIsActive) && (
            <Footer
              sendIconUrl={sendIconUrl}
              addUserResponseToHistory={(response) =>
                this.props.store.addUserResponseToHistory(response)
              }
              getLinkPreview={(url) => this.props.store.getLinkPreview(url)}
            />
          )}
        </Container>
      </div>
    );
  }

  getWelcomeBubblePosition(
    useChatIcon,
    chatIconPosition,
    chatLocationVertical,
    chatLocationHorizontal,
    chatIconHeight,
    chatIconWidth,
    chatMarginHorizontal,
    fixedSize,
    width,
    bubbleCornersArray,
    bubbleCornersArrayTemp,
  ) {
    let welcomeBubblePosition = '';

    const isBottom =
      useChatIcon === 'true'
        ? chatIconPosition.indexOf('bottom') > -1
        : chatLocationVertical === 'bottom';
    const isLeft =
      useChatIcon === 'true'
        ? chatIconPosition.indexOf('left') > -1
        : chatLocationHorizontal === 'left';

    if (useChatIcon === 'true') {
      const chatIconPositionPieces = chatIconPosition.split(';');
      chatIconPositionPieces.forEach((piece) => {
        if (piece.indexOf('bottom') > -1 || piece.indexOf('top') > -1) {
          welcomeBubblePosition +=
            piece.trimLeft().split(' ')[0] +
            ' calc(' +
            piece.trimLeft().split(' ')[1] +
            ' + 5px + ' +
            chatIconHeight +
            ');';
        } else if (piece.indexOf('left') > -1 || piece.indexOf('right') > -1) {
          welcomeBubblePosition +=
            piece.trimLeft().split(' ')[0] +
            ' calc(' +
            piece.trimLeft().split(' ')[1] +
            ' + calc(' +
            chatIconWidth +
            ' / 1.25));';
        }
      });
    } else {
      welcomeBubblePosition =
        chatLocationVertical +
        ': 65px; ' +
        chatLocationHorizontal +
        ': calc(calc(' +
        (chatMarginHorizontal ? chatMarginHorizontal : '20px') +
        ' + ' +
        (fixedSize === 'true' ? width : '380px') +
        ') / 1.33);';
    }

    if (isBottom) {
      if (isLeft) {
        bubbleCornersArray[2] = bubbleCornersArrayTemp[2];
        bubbleCornersArray[3] = bubbleCornersArrayTemp[3];
        bubbleCornersArray[0] = bubbleCornersArrayTemp[0];
        bubbleCornersArray[1] = bubbleCornersArrayTemp[1];
      } else {
        bubbleCornersArray[2] = bubbleCornersArrayTemp[3];
        bubbleCornersArray[3] = bubbleCornersArrayTemp[2];
        bubbleCornersArray[0] = bubbleCornersArrayTemp[1];
        bubbleCornersArray[1] = bubbleCornersArrayTemp[0];
      }
    } else {
      if (isLeft) {
        bubbleCornersArray[0] = bubbleCornersArrayTemp[2];
        bubbleCornersArray[1] = bubbleCornersArrayTemp[3];
        bubbleCornersArray[2] = bubbleCornersArrayTemp[0];
        bubbleCornersArray[3] = bubbleCornersArrayTemp[1];
      } else {
        bubbleCornersArray[0] = bubbleCornersArrayTemp[3];
        bubbleCornersArray[1] = bubbleCornersArrayTemp[2];
        bubbleCornersArray[2] = bubbleCornersArrayTemp[1];
        bubbleCornersArray[3] = bubbleCornersArrayTemp[0];
      }
    }
    return welcomeBubblePosition;
  }

  splitCornersToArray(shape) {
    const shapePieces = shape.split(' ');
    const shapeTopLeft =
      shapePieces.length === 1
        ? shapePieces[0]
        : shapePieces.length === 2
        ? shapePieces[0]
        : shapePieces[0];
    const shapeTopRight =
      shapePieces.length === 1
        ? shapePieces[0]
        : shapePieces.length === 2
        ? shapePieces[1]
        : shapePieces[1];
    const shapeBottomLeft =
      shapePieces.length === 1
        ? shapePieces[0]
        : shapePieces.length === 2
        ? shapePieces[1]
        : shapePieces[2];
    const shapeBottomRight =
      shapePieces.length === 1
        ? shapePieces[0]
        : shapePieces.length === 2
        ? shapePieces[0]
        : shapePieces[3];

    return [shapeTopLeft, shapeTopRight, shapeBottomLeft, shapeBottomRight];
  }
}

export default compose(inject('store'), observer)(App);
